import { Directions, LocationOn } from '@mui/icons-material';
import { Card, CardHeader, Chip, Icon, Link } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import { useUserProfile } from '../api/user';
import { CompanyDbCompany } from '../generated/graphql';
import { useI18n } from '../i18n/i18n';
import { InfoCardContent, InfoCell, InfoLine, InfoLineWithSkeleton } from './layout';

interface AddressCardProps extends HTMLAttributes<HTMLDivElement> {
  company?: CompanyDbCompany;
}

function getMapDirectionsHref(company: CompanyDbCompany, homeAddress?: string): string {
  const parts = [
    `https://www.google.com/maps/dir/${company.address_street_name}+${company.address_street_number},+${company.address_postal_code}+${company.address_town},+${company.address_country}`
  ];
  if (homeAddress) {
    parts.push(homeAddress.split(' ').join(',+'));
  }
  return parts.join('/');
}

function isValidUrl(urlCandidate: string): boolean {
  try {
    new URL(urlCandidate);
    return true;
  } catch (_) {
    return false;
  }
}

function toValidUrl(website?: string | null): string {
  if (!website) {
    return '';
  }
  if (isValidUrl(website)) {
    return website;
  }
  if (isValidUrl(`https://${website}`)) {
    return `//${website}`;
  }
  return '';
}

export const AddressCard: React.FC<AddressCardProps> = ({ company, ...domAttributes }) => {
  const { i18n } = useI18n();
  const userProfile = useUserProfile();
  const websiteUrl = toValidUrl(company?.website);
  return (
    <Card {...domAttributes}>
      <CardHeader
        title={i18n('address_card.title', 'Address')}
        action={
          !!company?.advertising_protection && (
            <Chip
              color="error"
              label={i18n('address_card.advertisement_protection_label', 'Advertisement protection')}
            />
          )
        }
        titleTypographyProps={{ variant: 'h5', component: 'h3' }}
        avatar={
          <Icon>
            <LocationOn />
          </Icon>
        }
      />
      {
        <InfoCardContent>
          <InfoCell>
            {company?.address_co_name && <InfoLine>{`co ${company.address_co_name}`}</InfoLine>}
            <InfoLineWithSkeleton entity={company}>
              {(company) => (
                <>
                  {`${company.address_street_name} ${company.address_street_number}`}
                  {company.address_floor ? `, ${company.address_floor}` : ''}
                </>
              )}
            </InfoLineWithSkeleton>
            <InfoLineWithSkeleton entity={company}>
              {(company) => `${company.address_postal_code} ${company.address_town}`}
            </InfoLineWithSkeleton>
            <InfoLineWithSkeleton entity={company}>
              {(company) => `${company.address_region}, ${company.address_country}`}
            </InfoLineWithSkeleton>
            <InfoLineWithSkeleton entity={company}>
              {(company) => (
                <Link
                  style={{ display: 'flex', alignItems: 'center' }}
                  href={getMapDirectionsHref(company, userProfile.data?.homeAddress)}
                  rel="noreferrer"
                  target="_blank"
                >
                  {i18n('address_card.directions', 'Directions')}
                  <Icon fontSize="small">
                    <Directions fontSize="small" />
                  </Icon>
                </Link>
              )}
            </InfoLineWithSkeleton>
          </InfoCell>
          <InfoCell>
            <InfoLineWithSkeleton entity={company} canWrap>
              {(company) => `Phone: ${company.contact_phone_number || ''}`}
            </InfoLineWithSkeleton>
            <InfoLineWithSkeleton entity={company} canWrap>
              {(company) => (
                <>
                  {i18n('address_card.email', 'Email: ')}
                  {company.contact_email && (
                    <Link href={`mailto:${company.contact_email}`} style={{ wordBreak: 'break-all' }}>
                      {company.contact_email}
                    </Link>
                  )}
                </>
              )}
            </InfoLineWithSkeleton>
            <InfoLineWithSkeleton entity={company} canWrap>
              {(company) => (
                <>
                  {i18n('address_card.web', 'Web: ')}
                  {websiteUrl && (
                    <Link target="_blank" rel="noreferer" href={`${websiteUrl}`} style={{ wordBreak: 'break-all' }}>
                      {company.website}
                    </Link>
                  )}
                </>
              )}
            </InfoLineWithSkeleton>
          </InfoCell>
        </InfoCardContent>
      }
    </Card>
  );
};
