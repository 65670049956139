import copyToClipboard from 'copy-to-clipboard';
import {
  CopyAll,
  MailOutline,
  Share,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined
} from '@mui/icons-material';
import { useState } from 'react';
import { useParams, useLocation, Outlet } from 'react-router-dom';

import { useCompany, useSetInteresting } from '../api/company';
import { InterestingModal } from '../components/interestingModal';
import { H1 } from '../components/layout';
import { SetFavoriteButton } from '../components/setFavoriteButton';
import { useTopbarActions } from '../components/topbar/topbarContext';
import { CompanyDbCompany } from '../generated/graphql';
import { PopupMenu } from '../components/PopupMenu';
import { NCinoLogo } from '../img/nCino-logo';
import { Icon } from '@mui/material';

function shareAsMail(subject: string, body: string): void {
  let anchor: HTMLAnchorElement | undefined;
  try {
    anchor = document.createElement('a') as HTMLAnchorElement;
    anchor.href = `mailto:?subject=${subject}&body=${body}`;
    anchor.target = '_blank';
    anchor.rel = 'noreferer';
    anchor.click();
  } finally {
    anchor?.remove();
  }
}

interface NavigationState {
  cid: string;
  name: string;
}

function getCompanyName(companyId: string, company?: CompanyDbCompany, state?: NavigationState): string {
  if (company?.name) {
    return company.name;
  }
  if (state?.cid === companyId && state.name) {
    return state.name;
  }
  return `Company ${companyId}`;
}

export interface CompanyContext {
  company?: CompanyDbCompany;
  companyId: string;
  isLoading: boolean;
}

export const Company: React.FC = () => {
  const { cid } = useParams<{ cid: string }>();
  const companyId = cid!;
  const company = useCompany(companyId);

  const { state: navigationState } = useLocation();

  const InterestingIcon = company.data?.interesting?.interesting ? ThumbUp : ThumbUpOutlined;
  const NotInterestingIcon = company.data?.interesting?.interesting === false ? ThumbDown : ThumbDownOutlined;

  const [interestingIsOpen, setInterestingIsOpen] = useState(false);
  const [notInterestingIsOpen, setNotInterestingIsOpen] = useState(false);

  const setInteresting = useSetInteresting(companyId);

  useTopbarActions(
    [
      {
        key: 'interesting',
        icon: <InterestingIcon fontSize="small" color="primary" />,
        action: () => setInterestingIsOpen(true)
      },
      {
        key: 'not-interesting',
        icon: <NotInterestingIcon fontSize="small" color="error" />,
        action: () => setNotInterestingIsOpen(true)
      },
      {
        key: 'share',
        icon: (
          <PopupMenu
            Icon={Share}
            items={[
              {
                key: 'mail',
                header: 'Mail',
                Icon: MailOutline,
                action: () => shareAsMail(`Company info for ${company.data?.name}`, location.href)
              },
              {
                key: 'clipboard',
                header: 'Copy to clipboard',
                Icon: CopyAll,
                action: () => copyToClipboard(location.href)
              }
            ]}
          />
        )
      },
      {
        key: 'favorite',
        icon: <SetFavoriteButton cid={companyId} favorite={company.data?.favorite} />
      }
    ],
    [company.data?.name, company.data?.favorite, company.data?.interesting?.interesting]
  );

  return (
    <div>
      <InterestingModal
        mode="interesting"
        companyId={companyId}
        isOpen={interestingIsOpen}
        onClose={() => setInterestingIsOpen(false)}
        interesting={company.data?.interesting || undefined}
        onSetInteresting={(value) =>
          setInteresting.mutateAsync({
            interesting: true,
            comment: value.comment,
            interestingOptionIds: value.optionIds.join(',')
          })
        }
      />
      <InterestingModal
        mode="not-interesting"
        companyId={companyId}
        isOpen={notInterestingIsOpen}
        onClose={() => setNotInterestingIsOpen(false)}
        interesting={company.data?.interesting || undefined}
        onSetInteresting={(value) =>
          setInteresting.mutateAsync({
            interesting: false,
            comment: value.comment,
            interestingOptionIds: value.optionIds.join(',')
          })
        }
      />

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <H1>{getCompanyName(companyId, company.data, navigationState as NavigationState)}</H1>
        {company.data?.ncino && (
          <Icon fontSize="large" style={{ marginLeft: 16 }}>
            <NCinoLogo />
          </Icon>
        )}
      </div>
      <Outlet context={{ company: company.data, companyId, isLoading: company.isLoading }} />
    </div>
  );
};
