import { Functions } from '@mui/icons-material';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  TableCellProps,
  InputBase,
  InputBaseProps,
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { HTMLAttributes, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDeleteSimulation, useUpdateSimulation } from '../api/company';
import { Financials, Maybe } from '../generated/graphql';
import { useI18n } from '../i18n/i18n';
import { Spinner } from './spinner';

interface FinanceDataGridProps extends HTMLAttributes<HTMLDivElement> {
  cid: string;
  holding: boolean;
  report?: Financials;
  isLoading: boolean;
  toggleSimulation: () => void;
}

function toNumber(value?: Maybe<number>): string | undefined {
  if (value === null || value === undefined) {
    return;
  }
  try {
    return Number(value).toLocaleString();
  } catch (_) {
    return value?.toLocaleString();
  }
}

const StyledTableRow = styled(({ headline, ...domAttributes }: { headline: boolean } & TableRowProps) => (
  <TableRow {...domAttributes} />
))`
  ${(p) =>
    p.headline &&
    css`
      background: ${grey.A100};
      > td {
        font-size: 1.1em;
        font-weight: 700;
        padding: 0 16px;
      }
    `}
`;

const getEditStyling = (
  canEdit: boolean,
  isEditing: boolean
): { ':hover': React.CSSProperties } | { ':focus-within': React.CSSProperties } | undefined => {
  if (isEditing) {
    return { ':focus-within': { background: `lightgray!important` } };
  }
  if (canEdit) {
    return { ':hover': { background: `lightgrey!important` } };
  }
  return undefined;
};

const InputCell: React.FC<InputBaseProps> = ({ value, defaultValue, onBlur, inputRef, ...domAttributes }) => (
  <InputBase
    {...domAttributes}
    inputRef={inputRef}
    sx={{ padding: 0 }}
    inputProps={{ style: { textAlign: 'right', padding: 0, fontSize: '0.875rem' }, sx: { padding: 0 } }}
    value={value}
    defaultValue={defaultValue}
    onBlur={onBlur}
  />
);

const Cell: React.FC<
  Omit<TableCellProps, 'children'> & { value?: string; canEdit: boolean; onStore?: (newValue: string) => Promise<void> }
> = ({ value, defaultValue, onStore, canEdit, align, tabIndex, ...domAttributes }) => {
  const inputRef = useRef<HTMLInputElement>();
  const [isEditing, setIsEditing] = useState(false);

  const store = useCallback(
    async (e: React.FocusEvent<HTMLInputElement>): Promise<void> => {
      if (!onStore) return;
      setIsEditing(false);
      const newValue = e.target.value;
      if (defaultValue !== newValue) {
        await onStore(newValue);
      }
    },
    [onStore, value, defaultValue, setIsEditing]
  );

  return (
    <TableCell
      {...domAttributes}
      sx={getEditStyling(canEdit, isEditing)}
      onFocus={() => {
        if (canEdit) setIsEditing(true);
        requestAnimationFrame(() => {
          inputRef.current?.focus();
          inputRef.current?.select();
          if (inputRef.current && tabIndex !== undefined) inputRef.current.tabIndex = tabIndex;
        });
      }}
      tabIndex={canEdit && !isEditing ? tabIndex : -1}
      align={align}
      onClick={canEdit && !isEditing ? () => setIsEditing(true) : undefined}
    >
      {isEditing ? (
        <InputCell
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          inputRef={inputRef}
          defaultValue={defaultValue}
          onBlur={store}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRef.current?.blur();
            }
          }}
        />
      ) : (
        value
      )}
    </TableCell>
  );
};

export const FinanceDataGrid2: React.FC<FinanceDataGridProps> = ({
  cid,
  holding,
  report,
  isLoading,
  toggleSimulation,
  ...domAttributes
}) => {
  if (isLoading) {
    return (
      <Paper {...domAttributes} sx={{ width: '100%', height: 1000, overflow: 'hidden' }}>
        <Spinner />
      </Paper>
    );
  }

  if (!report || !report.headers.length) {
    return <Typography sx={{ color: '#fff' }}>no data</Typography>;
  }
  const updateSimulation = useUpdateSimulation(cid, holding).mutateAsync;
  const deleteSimulation = useDeleteSimulation(cid, holding).mutateAsync;
  const { i18n } = useI18n();

  const canEdit = (rowIndex: number, columnIndex: number): boolean => {
    const row = report.rows[rowIndex];
    // @ts-ignore
    return (
      !!report.simulation[report.simulation.length - 1] &&
      !row?.headline &&
      (row?.editable === true || (report.simulation[columnIndex] && row?.editable !== false))
    );
  };
  // @ts-ignore
  const handleChangeSimulate = (event): void => {
    toggleSimulation();
  };
  return (
    <Paper {...domAttributes} sx={{ width: '100%', overflow: 'hidden' }}>
      <FormControlLabel
        control={<Switch checked={!!report.simulation[report.simulation.length - 1]} onChange={handleChangeSimulate} />}
        label={i18n('action.simulate', 'Simulate')}
        style={{ marginLeft: 4 }}
      />
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  minWidth: 200,
                  fontWeight: 700,
                  left: 0,
                  zIndex: 5
                }}
              ></TableCell>
              {report.ids.map((id, index) => (
                <TableCell
                  key={`${id}_${index}`}
                  align="right"
                  style={{
                    minWidth: 80,
                    fontWeight: 700
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{report.headers[index]}</span>
                    {!!deleteSimulation && report.simulation[index] && <Functions fontSize="small" />}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {report.rows.map((row, rowIndex) => {
              const headline = !!row?.headline;
              return (
                <StyledTableRow headline={headline} hover={!row?.headline} key={row?.field || row?.label}>
                  <Cell
                    canEdit={false}
                    style={{
                      minWidth: 200,
                      background: !row?.headline ? '#fff' : '',
                      position: 'sticky',
                      left: 0
                    }}
                    value={row?.label}
                    defaultValue={row?.label}
                    align="left"
                  />
                  {row?.values.map((value, index) => (
                    <Cell
                      key={index}
                      canEdit={canEdit(rowIndex, index)}
                      style={{
                        minWidth: 80,
                        background: !row?.headline ? '#fff' : '',
                        color: row.marks[index] ? 'blue' : 'black'
                      }}
                      tabIndex={index + report.ids.length * rowIndex}
                      value={toNumber(value)}
                      defaultValue={value !== undefined && value !== null ? value.toString() : ''}
                      align="right"
                      onStore={(value) =>
                        updateSimulation({
                          id: report.ids[index]!,
                          field: row?.field,
                          value: Number.parseFloat(value),
                          note: ''
                        })
                      }
                    />
                  ))}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
