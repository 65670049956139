import React from 'react';
import { Chip } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useCompanies } from '../api/company';
import { Filter } from '../generated/graphql';
import { CompanyList } from './companyList';
import { useUserProfile } from '../api/user';
import { regions } from '../api/api';
import { H2, topbarBreakWidth } from './layout';
import { useI18n } from '../i18n/i18n';

const loadingLeads = [...Array(21)].map((_, i) => ({ key: i }));

const LeadsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  > div {
    display: flex;
    align-items: center;
  }

  > button {
    display: none;
  }

  @media (max-width: ${topbarBreakWidth}px) {
    > button {
      display: block;
    }
  }
`;

export const LeadsGrid: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...domAttributes }) => {
  const { i18n } = useI18n();
  const userProfile = useUserProfile();
  const regionId = parseInt(userProfile.data?.region || '0');
  const region = regions.find((item) => item.id === regionId);

  const leads = useCompanies(Filter.Leads, regionId);
  const navigate = useNavigate();
  return (
    <>
      <LeadsHeaderContainer>
        <div style={{ padding: '32px 0 8px 0' }}>
          <H2 style={{ padding: 0, marginRight: 12 }}>{i18n('leads_grid.leads', 'Leads')}</H2>
          {region?.name && <Chip color="secondary" label={region?.name} onClick={() => navigate('/profile')} />}
        </div>
      </LeadsHeaderContainer>
      ;
      <CompanyList
        {...domAttributes}
        companies={(!leads.isLoading && leads.data) || []}
        loadingCompanies={leads.isLoading ? loadingLeads : undefined}
      />
    </>
  );
};
