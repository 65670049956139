import { graphqlApi } from './graphqlApi';
import { apiKey } from '../environment';
import { Profile } from '../generated/graphql';
import { Api } from './api';
import { Locale } from '../i18n/i18n';

const localStorageUserProfileKey = 'veto_user_profile';

export interface UserProfile extends Profile {
  homeAddress?: string;
  locale?: Locale;
}

const writeProfile = (profile?: UserProfile): void => {
  if (profile) {
    localStorage.setItem(localStorageUserProfileKey, JSON.stringify(profile));
  }
};

export const getUserProfile = (): UserProfile | undefined => {
  const profile = localStorage.getItem(localStorageUserProfileKey);

  if (profile) {
    try {
      return JSON.parse(profile);
    } catch (_) {
      /** intentionally left blank */
    }
  }
  if (apiKey) {
    return {
      name: 'unknown',
      email: 'api user'
    };
  }
  return undefined;
};

export const handleSignin = async (api: Api, email: string): Promise<void> => {
  await api.user.updateProfile({ email });
  const profile = await graphqlApi.user.getProfile();
  const browserProfile = getUserProfile();
  writeProfile({ ...browserProfile, ...profile });
};

export const handleSignout = (): void => localStorage.removeItem(localStorageUserProfileKey);

export const updateProfileSettings = async (api: Api, settings: Omit<UserProfile, 'email'>): Promise<void> => {
  writeProfile({ ...getUserProfile(), ...settings });
  const { region } = settings;
  await api.user.updateProfile({ region });
};

export const cachedLocale = (): Locale => getUserProfile()?.locale || 'en-US';
