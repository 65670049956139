import jwtDecode from 'jwt-decode';
import { cognitoClientId, cognitoEndpoint, cognitoRedirectURI } from '../environment';
import { graphqlApi } from './graphqlApi';
import * as profile from './userProfile';

const localStorageAuthKey = 'veto_auth_token';

const tokenEndpoint = `${cognitoEndpoint}/oauth2/token`;
const cognitoSignin = `${cognitoEndpoint}/login?client_id=${cognitoClientId}&response_type=code&scope=email+openid&redirect_uri=${cognitoRedirectURI}`;
const congitoSignout = `${cognitoEndpoint}/logout?client_id=${cognitoClientId}&logout_uri=${location.origin}&redirect_uri=${location.origin}&response_type=code&scope=email+openid`;

export const redirectToCognitoLogin = (): void => window.location.assign(cognitoSignin);

const fetchToken = async (
  code: string
): Promise<{ token: string; expiresInSeconds: number; email: string; user: string }> => {
  const params = [
    ['code', code],
    ['grant_type', 'authorization_code'],
    ['client_id', cognitoClientId],
    ['redirect_uri', cognitoRedirectURI]
  ];

  const formData = params.map(([key, value]) => `${key}=${value}`).join('&');

  const response = await fetch(tokenEndpoint, {
    method: 'POST',
    mode: 'cors',
    credentials: 'omit',
    body: formData,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  });

  const { access_token: token, expires_in: expiresInSeconds, id_token: idToken } = await response.json();
  const { email, 'cognito:username': user } = jwtDecode<{ email: string; 'cognito:username': string }>(idToken);
  return { token, expiresInSeconds, email, user };
};

export const authorize = async (): Promise<void> => {
  const code = new URLSearchParams(location.search).get('code');
  if (!code) {
    throw new Error('no token code returned from cognito!');
  }
  const { token, expiresInSeconds, email } = await fetchToken(code);
  localStorage.setItem(
    localStorageAuthKey,
    JSON.stringify({ token, tokenExpiry: Date.now() + 1000 * expiresInSeconds })
  );
  await profile.handleSignin(graphqlApi, email);
};

export const getAuthToken = (): string | void => {
  const token = localStorage.getItem(localStorageAuthKey);
  if (!token) return;

  try {
    const parsed = JSON.parse(token);
    if (Date.now() < parsed.tokenExpiry) {
      return parsed.token;
    }
  } catch (_) {
    /** intentionally left blank */
  }
};

export const signOut = async (): Promise<void> => {
  localStorage.removeItem(localStorageAuthKey);
  profile.handleSignout();
  window.location.assign(congitoSignout);
};

export class AuthError extends Error {
  public constructor(message: string) {
    super(message);
  }
}
