import { createContext, DependencyList, useContext, useEffect, useState } from 'react';

export interface RelativeRoute {
  header: string;
  path: string;
  icon: JSX.Element;
}

interface TopbarContextProps {
  actions?: CustomAction[];
  relativeRoutes?: RelativeRoute[];
}

export interface CustomAction {
  key: string;
  icon: JSX.Element;
  action?: () => void;
}

type SetActions = (actions?: CustomAction[]) => void;
type SetRelativeRoutes = (routes?: RelativeRoute[]) => void;

const TopbarContext = createContext<{
  topbarContext?: TopbarContextProps;
  setActions?: SetActions;
  setRelativeRoutes?: SetRelativeRoutes;
}>({});

export const TopbarContextProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [actions, setActions] = useState<CustomAction[] | undefined>();
  const [relativeRoutes, setRelativeRoutes] = useState<RelativeRoute[] | undefined>([]);
  return (
    <TopbarContext.Provider value={{ topbarContext: { actions, relativeRoutes }, setActions, setRelativeRoutes }}>
      {children}
    </TopbarContext.Provider>
  );
};

export const useConsumeTopbarProperties = (): {
  actions?: CustomAction[];
  relativeRoutes?: RelativeRoute[];
} => {
  const { topbarContext } = useContext(TopbarContext);
  if (!topbarContext) {
    throw new Error('TopbarContext is not initialized!');
  }
  return topbarContext;
};

export const useTopbarActions = (actions: CustomAction[], dependencies: DependencyList): void => {
  const { setActions } = useContext(TopbarContext);
  if (!setActions) {
    throw new Error('TopbarContext is not initialized!');
  }
  useEffect(() => {
    setActions(actions);
    return () => setActions(undefined);
  }, dependencies);
};

export const useRelativeRoutes = (relativeRoutes?: RelativeRoute[]): void => {
  const { setRelativeRoutes } = useContext(TopbarContext);
  if (!setRelativeRoutes) {
    throw new Error('TopbarContext is not initialized!');
  }
  useEffect(() => {
    setRelativeRoutes(relativeRoutes);
    return () => setRelativeRoutes(undefined);
  }, [...(relativeRoutes?.map((r) => r.header) || [])]);
};
