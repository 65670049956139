import { HTMLAttributes, memo } from 'react';
import styled from 'styled-components';
import { Handle, Position } from 'react-flow-renderer';
import { CompanyDbCompany, Maybe } from '../../generated/graphql';
import { Icon, Typography } from '@mui/material';
import { CorporateFare, Person } from '@mui/icons-material';
import { EdgeIndex } from './dagreGraph';
import { useI18n } from '../../i18n/i18n';
import { Link } from 'react-router-dom';

const Container = styled.div`
  border-radius: 5px;
  border: 1px solid black;
  background: #fff;
  padding: 8px;
  width: 250px;
`;

interface CompanyGraphNodeProps {
  data: {
    isTarget: boolean;
    name: string;
    company?: CompanyDbCompany;
    type: 'Person' | 'Company';
    sources: EdgeIndex[];
  };
  isConnectable: boolean;
}

const CompanyContainer = styled.div`
  display: grid;
  gap: 4px 20px;
  grid-template-columns: 1fr 1fr;
`;

const CompanyData = styled.div`
  font-size: 14px;
`;

const Description = styled(CompanyData)`
  white-space: nowrap;
`;

const CompanyRow: React.FC<{ description: string; formattedValue: string }> = ({ description, formattedValue }) => (
  <>
    <Description>{description}</Description>
    <CompanyData>{formattedValue}</CompanyData>
  </>
);

const toMillion = (value?: Maybe<number>): string => {
  if (!value) {
    return '-';
  }
  try {
    const number = Number(value);
    return `${(number / 1000000).toFixed(1)} mDKK`;
  } catch (_) {
    return `${value} kr`;
  }
};

const toPercent = (value?: Maybe<number>): string => {
  if (!value) {
    return '-';
  }
  try {
    const number = Number(value);
    return `${(100 * number).toFixed(2)} %`;
  } catch (_) {
    return `${value}`;
  }
};

interface CompanyInfoProps extends HTMLAttributes<HTMLDivElement> {
  company: CompanyDbCompany;
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({ company, ...domAttributes }) => {
  const { i18n } = useI18n();
  return (
    <>
      <Typography variant="overline">
        {i18n('company_structure.node.subheader', 'CVR {company_registry_id} - est. {founded}', {
          company_registry_id: company.company_registry_id,
          founded: company.founded
        })}
      </Typography>
      <CompanyContainer {...domAttributes}>
        <CompanyRow description={i18n('caption.rating', 'Rating')} formattedValue={company.rating?.toFixed(0) || '-'} />
        {company.key_figures && (
          <>
            <CompanyRow
              description={i18n('key_figures.adjusted_equity', 'Adjusted equity')}
              formattedValue={toMillion(company.key_figures.adjusted_equity)}
            />
            <CompanyRow
              description={i18n('key_figures.equity-total_assets', 'Equity/total assets')}
              formattedValue={toPercent(company.key_figures.equity_ratio)}
            />
            <CompanyRow
              description={i18n('key_figures.net_debt', 'Net debt')}
              formattedValue={toMillion(company.key_figures.net_debt)}
            />
            <CompanyRow
              description={i18n('key_figures.ebitda', 'EBITDA')}
              formattedValue={toMillion(company.key_figures.ebitda)}
            />
            <CompanyRow
              description={i18n('key_figures.net_debt-ebitda', 'Net debt/EBITDA')}
              formattedValue={company.key_figures.net_debt_to_ebitda?.toFixed(2) || '-'}
            />
            <CompanyRow
              description={i18n('key_figures.net_debt-cashflow', 'Net debt/cashflow')}
              formattedValue={company.key_figures.net_debt_to_cffo?.toFixed(2) || '-'}
            />

            <CompanyRow
              description={i18n('key_figures.return_on_equity', 'Return on equity')}
              formattedValue={toPercent(company.key_figures.return_on_equity)}
            />
          </>
        )}
      </CompanyContainer>
    </>
  );
};

const HeaderLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  :focus,
  :hover {
    text-decoration: underline;
  }
`;

const CompanyGraphNodeComponent: React.FC<CompanyGraphNodeProps> = ({ data, isConnectable }) => {
  const { sources, isTarget, name, company, type } = data;
  return (
    <>
      {isTarget && <Handle type="target" position={Position.Top} isConnectable={isConnectable} id="target" />}
      <Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: type === 'Person' ? 'center' : 'flex-start'
          }}
        >
          <div style={{ marginRight: 8 }}>
            <Icon fontSize="small">{type === 'Person' ? <Person /> : <CorporateFare />}</Icon>
          </div>
          <Typography lineHeight={1} fontSize={14} fontWeight={700} variant="overline">
            {type === 'Company' ? (
              <HeaderLink to={`/companies/${company?.cid}/company-structure/`}>{name}</HeaderLink>
            ) : (
              name
            )}
          </Typography>
        </div>
        {company && <CompanyInfo company={company} />}
      </Container>
      <>
        {sources.map((s) => (
          <Handle
            key={s.id}
            type="source"
            style={{ left: `${(100 * (0.5 + s.index)) / s.of}%` }}
            position={Position.Bottom}
            id={s.id}
            isConnectable={isConnectable}
          />
        ))}
      </>
    </>
  );
};

export const CompanyGraphNode = memo(CompanyGraphNodeComponent);
