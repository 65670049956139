const devCognitoClientId = '6j7pmfvs0nntmov1o74ppur7uo';
const devCognitoEndpoint = 'https://heimdall-develop.auth.eu-central-1.amazoncognito.com';
const devApiEndpoint = 'https://bw3j5ccs2fb4df4wjbjlpo23u4.appsync-api.eu-central-1.amazonaws.com/graphql';

/**
 * NOTE: Environment variables are built into the production bundle, and must this be known
 * at build time.
 */

export const cognitoClientId =
  __VETO_APP_CONFIG__?.cognitoClientId || process.env.REACT_APP_cognitoClientId || devCognitoClientId;
export const cognitoEndpoint =
  __VETO_APP_CONFIG__?.cognitoEndpoint || process.env.REACT_APP_cognitoEndpoint || devCognitoEndpoint;
export const cognitoRedirectURI = `${location.origin}/authorize`;
export const apiEndpoint = __VETO_APP_CONFIG__?.apiEndpoint || process.env.REACT_APP_API_ENDPOINT || devApiEndpoint;
export const apiKey = process.env.REACT_APP_API_KEY;
