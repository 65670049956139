import { useOutletContext } from 'react-router-dom';
import { CompanyStructureGraph } from '../../components/company-structure/companyStructure';

import { H2 } from '../../components/layout';
import { useI18n } from '../../i18n/i18n';
import { CompanyContext } from '../company';

export const CompanyStructurePage: React.FC = () => {
  const { i18n } = useI18n();
  const { companyId, company } = useOutletContext<CompanyContext>();
  return (
    <>
      <H2>{i18n('company_overview.company_structure', 'Company structure')}</H2>
      <CompanyStructureGraph cid={companyId} companyName={company?.name || ''} />
    </>
  );
};
