import React, { createContext, useContext } from 'react';
import {
  CompanyDbCompany,
  CompanyDbInterestingOption,
  CompanyReport,
  CompanyStructure,
  FieldDataInput,
  Filter,
  FollowUp,
  ReportType,
  PersonalRelation,
  Activity,
  Profile,
  Financials
} from '../generated/graphql';

export type InterestingOptionsKind = 'interesting' | 'not-interesting';

export interface Region {
  id: number;
  name: string;
}

export const regions: Region[] = [
  {
    id: 0,
    name: 'Hele landet'
  },
  {
    id: 1,
    name: 'Region Nordjylland'
  },
  {
    id: 2,
    name: 'Region Midtjylland'
  },
  {
    id: 3,
    name: 'Region Syddanmark'
  },
  {
    id: 4,
    name: 'Region Hovedstaden'
  },
  {
    id: 5,
    name: 'Region Sjælland'
  }
];

export interface Api {
  user: {
    getFollowUps: () => Promise<FollowUp>;
    getProfile: () => Promise<Profile>;
    updateProfile: (profile: Profile) => Promise<void>;
  };
  company: {
    list: (filter?: Filter, regionId?: number) => Promise<CompanyDbCompany[]>;
    search: (search: string, signal?: AbortSignal) => Promise<CompanyDbCompany[]>;
    get: (cid: string) => Promise<CompanyDbCompany>;
    getCompanyStructure: (cid: string) => Promise<CompanyStructure>;
    updateOwnershipPercentage: (owner: string, subsidiary: string, ownershipPercentage: string) => Promise<void>;
    getCompanyReport: (cid: string, reportType: ReportType) => Promise<CompanyReport>;
    getRelations: (psid: string) => Promise<PersonalRelation[]>;
    setFavourite: (cid: string, isFavourite: boolean) => Promise<void>;
    setInteresting: (cid: string, interesting: boolean, comment: string, interestingOptionIds: string) => Promise<void>;
    unsetInteresting: (cid: string) => Promise<void>;
    getActivities: (cid: string) => Promise<Activity[]>;
    addActivity: (cid: string, type: string, note: string, follow_up_date?: string) => Promise<Activity>;
    updateActivity: (cid: string, activityId: string, note: string, follow_up_date?: string) => Promise<void>;
    deleteActivity: (cid: string, activityId: string) => Promise<void>;
    markActivityDone: (cid: string, activityId: string) => Promise<void>;
    saveFinancialPeriod: (cid: string, period: string, fields: FieldDataInput[]) => Promise<void>;
    deleteFinancialPeriod: (cid: string, period: string) => Promise<void>;
    transferToCrm: (cid: string) => Promise<void>;
    //
    getFinancials: (cid: string, holding: boolean, reportType: ReportType, simulate: boolean) => Promise<Financials>;
    addSimulation: (cid: string, holding: boolean) => Promise<void>;
    updateSimulation: (
      cid: string,
      holding: boolean,
      id: string,
      field: string,
      value: number,
      note: string
    ) => Promise<void>;
    deleteSimulation: (cid: string, holding: boolean, id: string) => Promise<void>;
  };
  options: {
    getInterestingOptions(kind: InterestingOptionsKind): Promise<CompanyDbInterestingOption[]>;
  };
}

const initialApi: Api = {
  user: {
    getFollowUps: () => {
      throw new Error('not implemented');
    },
    getProfile: () => {
      throw new Error('not implemented');
    },
    updateProfile: () => {
      throw new Error('not implemented');
    }
  },
  company: {
    list: () => {
      throw new Error('not implemented');
    },
    search: () => {
      throw new Error('not implemented');
    },
    get: () => {
      throw new Error('not implemented');
    },
    getCompanyStructure: () => {
      throw new Error('not implemented');
    },
    updateOwnershipPercentage: () => {
      throw new Error('not implemented');
    },
    getCompanyReport: () => {
      throw new Error('not implemented');
    },
    getRelations: () => {
      throw new Error('not implemented');
    },
    setFavourite: () => {
      throw new Error('not implemented');
    },
    setInteresting: () => {
      throw new Error('not implemented');
    },
    unsetInteresting: () => {
      throw new Error('not implemented');
    },
    getActivities: () => {
      throw new Error('not implemented');
    },
    addActivity: () => {
      throw new Error('not implemented');
    },
    updateActivity: () => {
      throw new Error('not implemented');
    },
    deleteActivity: () => {
      throw new Error('not implemented');
    },
    markActivityDone: () => {
      throw new Error('not implemented');
    },
    saveFinancialPeriod: () => {
      throw new Error('not implemented');
    },
    deleteFinancialPeriod: () => {
      throw new Error('not implemented');
    },
    transferToCrm: () => {
      throw new Error('not implemented');
    },
    getFinancials: () => {
      throw new Error('not implemented');
    },
    addSimulation: () => {
      throw new Error('not implemented');
    },
    updateSimulation: () => {
      throw new Error('not implemented');
    },
    deleteSimulation: () => {
      throw new Error('not implemented');
    }
  },
  options: {
    getInterestingOptions: () => {
      throw new Error('not implemented');
    }
  }
};

const ApiContext = createContext<Api>(initialApi);

export interface ApiProviderProps {
  children: JSX.Element;
  api: Api;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children, api }) => (
  <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
);

export const useApi = (): Api => useContext(ApiContext);
