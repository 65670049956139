import { toPng } from 'html-to-image';

function isSameOriginUrl(potentialUrl: string): boolean {
  const a = document.createElement('a') as HTMLAnchorElement;
  a.href = potentialUrl;
  const isSameOrigin = a.origin === window.location.origin;
  a.remove();
  return isSameOrigin;
}

function isEncodedImage(src: string): boolean {
  return !!src && src.startsWith('data:image');
}

async function imageToBase64(imgUrl: string): Promise<string> {
  const response = await fetch(imgUrl);
  const blob = await response.blob();
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

function extractGlobalStyling(): string[] {
  const cssRules: string[] = [];
  for (const sheet of Array.from(document.styleSheets)) {
    if (sheet.href && !sheet.href.startsWith(window.location.origin)) {
      continue;
    }
    for (const rule of Array.from(sheet.cssRules)) {
      cssRules.push(rule.cssText);
    }
  }
  return cssRules;
}

async function localiseImageSources(images: HTMLImageElement[]): Promise<void> {
  for (const image of images) {
    const src = image.getAttribute('src');
    if (!src || isEncodedImage(src)) continue;
    else if (isSameOriginUrl(src)) {
      const base64 = await imageToBase64(src);
      image.setAttribute('src', base64);
    } else {
      image.removeAttribute('src');
    }
  }
}

function createIframe(height: number, width: number): HTMLIFrameElement | undefined {
  const iframeElement = document.createElement('iframe') as HTMLIFrameElement;
  document.body.appendChild(iframeElement);
  if (!iframeElement?.contentDocument) {
    return;
  }

  const cssRules = extractGlobalStyling();
  const styleNode = iframeElement.contentDocument.createElement('style');
  styleNode.innerHTML = cssRules.join('\n');
  iframeElement.contentDocument.head.appendChild(styleNode);
  iframeElement.style.height = `${height}px`;
  iframeElement.style.width = `${width}px`;
  return iframeElement;
}

async function cloneElement(element: HTMLElement, iframeElement: HTMLIFrameElement): Promise<HTMLElement> {
  const elementClone = element.cloneNode(true) as HTMLElement;
  elementClone.setAttribute('id', 'cloned-element');
  iframeElement.contentDocument!.body.appendChild(elementClone);

  const images = iframeElement.contentDocument!.querySelectorAll('#cloned-element img');
  await localiseImageSources(Array.from(images) as HTMLImageElement[]);
  return elementClone;
}

function download(name: string, data: string): void {
  const link = document.createElement('a');
  link.download = `${name}.png`;
  link.href = data;
  link.click();
  link.remove();
}

export async function captureImage(element: HTMLElement, name: string): Promise<void> {
  const { offsetHeight: height, offsetWidth: width } = element;
  const iframeElement = createIframe(height, width);
  if (!iframeElement) {
    return;
  }

  try {
    const elementClone = await cloneElement(element, iframeElement);
    const imageData = await toPng(elementClone);
    download(name, imageData);
  } finally {
    document.body.removeChild(iframeElement);
    iframeElement.remove();
  }
}
