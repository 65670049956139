import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorize } from '../api/auth';
import { useI18n } from '../i18n/i18n';

export const SignInCallback: React.FC = () => {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  useEffect(() => {
    authorize().then(() => navigate('/'));
  }, []);
  return <Typography sx={{ color: '#fff' }}>{i18n('pages.signin.signin_message', 'Signing in...')}</Typography>;
};
