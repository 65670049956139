import { QueryClientProvider } from 'react-query';
import { ApiProvider } from './api/api';
import { graphqlApi } from './api/graphqlApi';
import { Navigation } from './components/navigation';
import { TopbarContextProvider } from './components/topbar/topbarContext';
import { Company } from './pages/company';
import { Connections } from './pages/connections';
import { Profile } from './pages/profile';
import { Dashboard } from './pages/dashboard';
import { SearchContextProvider, SearchPage } from './pages/search';
import { SignInCallback } from './pages/signin';
import {
  AccountTree,
  CorporateFare,
  Home,
  Login,
  Person,
  Search,
  Settings,
  Star,
  TableChart,
  TableRows,
  Topic
} from '@mui/icons-material';
import { CompanyOverviewPage } from './pages/company/company-overview-page';
import { CompanyStructurePage } from './pages/company/company-structure-page';
import { FinancialsPage } from './pages/company/financials-page';
import { CreditMemoPage } from './pages/company/credit-memo-page';
import { queryClient } from './api/company';
import { FavouritesPage } from './pages/favorites-page';
import { PortfolioPage } from './pages/portfolio-page';
import { TranslationProvider, useI18n } from './i18n/i18n';

const RouteSetup: React.FC = () => {
  const { i18n } = useI18n();
  return (
    <Navigation
      routes={[
        {
          header: i18n('routes.header.sign_in', 'Sign in callback'),
          path: '/authorize',
          pageComponent: <SignInCallback />,
          icon: <Login />
        },
        {
          header: i18n('routes.header.home', 'Home'),
          path: '/',
          menuEntry: 'always',
          icon: <Home />,
          pageComponent: <Dashboard />,
          auth: true
        },

        {
          header: i18n('routes.header.favorites', 'Favorites'),
          path: 'favorites',
          pageComponent: <FavouritesPage />,
          menuEntry: 'always',
          icon: <Star />
        },
        {
          header: i18n('routes.header.portfolio', 'Portfolio'),
          path: 'portfolio',
          pageComponent: <PortfolioPage />,
          menuEntry: 'always',
          icon: <Topic />
        },
        {
          header: i18n('routes.header.company', 'Company'),
          path: 'companies/:cid',
          auth: true,
          pageComponent: <Company />,
          icon: <CorporateFare />,
          nestedRoutes: [
            {
              header: i18n('routes.header.overview', 'Overview'),
              path: '',
              pageComponent: <CompanyOverviewPage />,
              menuEntry: 'always',
              icon: <CorporateFare />
            },
            {
              header: i18n('routes.header.structure', 'Structure'),
              path: 'company-structure',
              pageComponent: <CompanyStructurePage />,
              menuEntry: 'always',
              icon: <AccountTree />
            },
            {
              header: i18n('routes.header.credit_memo', 'Credit memo'),
              path: 'credit-memo',
              pageComponent: <CreditMemoPage />,
              menuEntry: 'always',
              icon: <TableRows />
            },
            {
              header: i18n('routes.header.financials', 'Financials'),
              path: 'financials',
              pageComponent: <FinancialsPage />,
              menuEntry: 'always',
              icon: <TableChart />
            }
          ]
        },
        {
          header: i18n('routes.header.connections', 'Connections'),
          path: '/connections/:psid',
          pageComponent: <Connections />,
          auth: true,
          icon: <Person />,
          menuEntry: 'relative'
        },
        {
          header: i18n('routes.header.profile', 'Profile'),
          path: '/profile',
          pageComponent: <Profile />,
          auth: true,
          icon: <Settings />
        },
        {
          header: i18n('routes.header.search', 'Search'),
          path: '/search',
          pageComponent: <SearchPage />,
          auth: true,
          icon: <Search />
        }
      ]}
    />
  );
};

function App(): JSX.Element {
  return (
    <div style={{ maxWidth: 1200, margin: '0 auto', paddingBottom: 50 }}>
      <ApiProvider api={graphqlApi}>
        <QueryClientProvider client={queryClient}>
          <TranslationProvider>
            <TopbarContextProvider>
              <SearchContextProvider>
                <RouteSetup />
              </SearchContextProvider>
            </TopbarContextProvider>
          </TranslationProvider>
        </QueryClientProvider>
      </ApiProvider>
    </div>
  );
}

export default App;
