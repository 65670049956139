import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { FollowUp } from '../generated/graphql';
import { useApi } from './api';
import { getUserProfile, updateProfileSettings, UserProfile } from './userProfile';

export const followUpsCacheKey = 'followups';
const userProfileCacheKey = 'user-profile';

export const useFollowUp = (): UseQueryResult<FollowUp[], Error> => {
  const api = useApi();
  return useQuery(followUpsCacheKey, () => api.user.getFollowUps());
};

export const useUserProfile = (): UseQueryResult<UserProfile, Error> => {
  return useQuery(userProfileCacheKey, () => getUserProfile);
};

export const useUpdateUserProfile = (): UseMutationResult<void, Error, UserProfile, void> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, UserProfile, void>(
    userProfileCacheKey,
    (profile) => updateProfileSettings(api, profile),
    {
      onSettled: () => queryClient.invalidateQueries(userProfileCacheKey)
    }
  );
};
