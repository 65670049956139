import { Clear } from '@mui/icons-material';
import { CompanyList } from '../components/companyList';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTopbarActions } from '../components/topbar/topbarContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../api/api';
import { CompanyDbCompany } from '../generated/graphql';
import { H1 } from '../components/layout';
import { useI18n } from '../i18n/i18n';

type SearchFunction = (searchTerm: string) => void;

const SearchContext = createContext<{
  searchTerm?: string;
  search?: SearchFunction;
  clearSearch?: () => void;
}>({});

export const SearchContextProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <SearchContext.Provider value={{ searchTerm, search: setSearchTerm, clearSearch: () => setSearchTerm('') }}>
      {children}
    </SearchContext.Provider>
  );
};

let didNavigate = false;

export const useSearch = (): { search: SearchFunction; clearSearch: () => void } => {
  const location = useLocation();
  const navigate = useNavigate();
  const didNavigateRef = useRef<boolean>();
  const { search, clearSearch } = useContext(SearchContext);
  return {
    search: useCallback(
      (term) => {
        if (location.pathname !== '/search') {
          navigate('/search');
          didNavigate = true;
        }
        if (search) {
          search(term);
        }
      },
      [location, navigate, didNavigateRef]
    ),
    clearSearch: useCallback(() => {
      if (didNavigate && location.pathname === '/search') {
        navigate(-1);
      }
      didNavigate = false;
      if (clearSearch) {
        clearSearch();
      }
    }, [location, navigate, didNavigateRef])
  };
};

export const SearchPage: React.FC = () => {
  const { i18n } = useI18n();
  const { clearSearch } = useSearch();
  useTopbarActions(
    [
      {
        key: 'dismiss-search',
        icon: <Clear />,
        action: clearSearch
      }
    ],
    []
  );

  const { searchTerm } = useContext(SearchContext);
  const api = useApi();
  const [companies, setCompanies] = useState<CompanyDbCompany[]>([]);

  const controller = useRef<AbortController>();

  useEffect(() => {
    controller.current = new AbortController();
    // abort all requests once the component unmounts
    return () => controller.current?.abort();
  }, []);

  useEffect(() => {
    const doSearch = async (): Promise<void> => {
      if (!searchTerm || searchTerm.length < 3) {
        return;
      }
      const companies = await api.company.search(searchTerm, controller.current?.signal);
      if (!controller.current?.signal.aborted) {
        setCompanies(companies);
      }
    };
    doSearch();
  }, [searchTerm]);

  return (
    <div>
      <H1>{i18n('pages.search', 'Search')}</H1>
      <CompanyList companies={companies} />
    </div>
  );
};
