import { Star, StarBorder } from '@mui/icons-material';
import { Icon, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSetFavorite } from '../api/company';
import { kompas } from './layout';

const FavoriteIcon: React.FC<{ favorite?: boolean | null }> = ({ favorite }) => {
  return favorite ? (
    <>
      <svg width={0} height={0}>
        <linearGradient rotate={'135deg'} id="linearColors" x1={0} y1={0} x2={1} y2={1}>
          <stop offset={0.2} stopColor={kompas.colors.blue} />
          <stop offset={0.8} stopColor={kompas.colors.red} />
        </linearGradient>
      </svg>
      <Star sx={{ fill: 'url(#linearColors)' }} />
    </>
  ) : (
    <StarBorder />
  );
};

const SetFavoriteButtonAction: React.FC<{ cid: string; favorite?: boolean | null }> = ({ cid, favorite }) => {
  const setFavorite = useSetFavorite(cid);
  const [isFavorite, setIsFavorite] = useState(favorite);
  useEffect(() => setIsFavorite(favorite), [favorite]);

  return (
    <IconButton
      onClick={(e) => {
        e.bubbles = false;
        e.stopPropagation();
        e.preventDefault();
        setFavorite.mutate(!favorite);
        setIsFavorite(!isFavorite);
      }}
    >
      {isFavorite ? (
        <>
          <svg width={0} height={0}>
            <linearGradient rotate={'135deg'} id="linearColors" x1={0} y1={0} x2={1} y2={1}>
              <stop offset={0.2} stopColor={kompas.colors.blue} />
              <stop offset={0.8} stopColor={kompas.colors.red} />
            </linearGradient>
          </svg>
          <Star sx={{ fill: 'url(#linearColors)' }} />
        </>
      ) : (
        <StarBorder />
      )}
    </IconButton>
  );
};

export const SetFavoriteButton: React.FC<{ cid?: string; favorite?: boolean | null }> = ({ cid, favorite }) => {
  return cid ? (
    <SetFavoriteButtonAction cid={cid} favorite={favorite} />
  ) : (
    <Icon style={{ padding: 8 }} color="disabled">
      <FavoriteIcon favorite={favorite} />
    </Icon>
  );
};
