import styled from 'styled-components';
import { AddressCard } from '../../components/addressCard';
import { ActivityLog } from '../../components/activityLog';
import { CompanyInfoCard } from '../../components/companyInfoCard';
import {
  CreditExceptionsCard,
  CreditMaxCard,
  CreditRatingCard,
  LeadProbabilityCard
} from '../../components/creditAnalysisCard';
import { gridGap, H2, H3, ResponsiveColumnGrid } from '../../components/layout';
import { PersonGrid } from '../../components/personCard';
import { CompanyDbPerson } from '../../generated/graphql';
import { useOutletContext } from 'react-router-dom';
import { CompanyContext } from '../company';
import { useI18n } from '../../i18n/i18n';

const OverviewGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: ${gridGap};

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const CompanyOverviewPage: React.FC = () => {
  const { i18n } = useI18n();
  const { companyId, company, isLoading } = useOutletContext<CompanyContext>();

  return (
    <>
      <H2>{i18n('company_overview.header', 'Overview')}</H2>
      <OverviewGrid>
        <AddressCard company={company} />
        <CompanyInfoCard company={company} />
      </OverviewGrid>
      <ActivityLog style={{ marginTop: 20 }} cid={companyId} ncino={!!company?.ncino} />
      <H2>{i18n('company_overview.credit_analysis', 'Credit analysis')}</H2>
      <ResponsiveColumnGrid count={4} itemMinWidth={250} style={{ gridAutoRows: '1fr' }}>
        <CreditRatingCard
          rating={company?.rating || 0}
          breakdown={
            company && [
              { name: i18n('key_figures.equity_ratio', 'Equity ratio'), value: company.equity_ratio_score! },
              {
                name: i18n('key_figures.net_debt-ebitda', 'Net debt/EBITDA'),
                value: company.net_debt_to_ebitda_score!
              },
              {
                name: i18n('key_figures.net_debt-cashflow', 'Net debt/cashflow'),
                value: company.net_debt_to_cffo_score!
              },
              { name: i18n('key_figures.return_on_equity', 'Return on equity'), value: company.return_on_equity_score! }
            ]
          }
        />
        <LeadProbabilityCard probability={company?.probability || 0} />
        <CreditExceptionsCard
          creditExceptions={Object.entries(company || {})
            .filter(([key, value]) => key.startsWith('credit_flag_') && value)
            .map(([key]) => key)}
        />
        <CreditMaxCard creditMax={company?.max_loan_amount || 0} />
      </ResponsiveColumnGrid>
      {(isLoading || !!company?.directors.length) && (
        <>
          <H3>{i18n('company_overview.directors', 'Directors')}</H3>
          <PersonGrid persons={company?.directors as CompanyDbPerson[]} isLoading={isLoading} />
        </>
      )}
      {(isLoading || !!company?.board_of_directors.length) && (
        <>
          <H3>{i18n('company_overview.board_members', 'Board members')}</H3>
          <PersonGrid persons={company?.board_of_directors as CompanyDbPerson[]} isLoading={isLoading} />
        </>
      )}
    </>
  );
};
