import { Person } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Icon } from '@mui/material';
import { HTMLAttributes } from 'react';
import { CompanyDbPerson } from '../generated/graphql';
import { InfoCell, InfoLine, ResponsiveColumnGrid } from './layout';
import { SkeletonElement } from './skeletonElement';
import { useNavigate } from 'react-router-dom';
import { useI18n } from '../i18n/i18n';

interface PersonCardProps extends HTMLAttributes<HTMLDivElement> {
  person?: CompanyDbPerson;
}

export const PersonCard: React.FC<PersonCardProps> = ({ person, ...domAttributes }) => {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const { style, ...rest } = domAttributes;
  return (
    <Card
      {...rest}
      style={{ ...style, cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      onClick={person ? () => navigate(`/connections/${person?.psid}/`, { state: { person } }) : undefined}
    >
      <CardHeader
        title={person ? person.name : <SkeletonElement width="80%" />}
        subheader={person ? person.role : <SkeletonElement width="80%" />}
        avatar={
          <Icon>
            <Person />
          </Icon>
        }
      />
      <CardContent>
        <InfoCell>
          <InfoLine>{i18n('person_card.since', 'Since: {since}', { since: person?.period_begin || '' })}</InfoLine>
          <InfoLine>
            {i18n('person_card.connections', 'Connections: {connections}', { connections: person?.connections || '' })}
          </InfoLine>
        </InfoCell>
      </CardContent>
    </Card>
  );
};

export const PersonGrid: React.FC<{ persons?: CompanyDbPerson[]; isLoading: boolean }> = ({ persons, isLoading }) => (
  <ResponsiveColumnGrid count={persons?.length || 1}>
    {!isLoading && persons?.map((p) => <PersonCard key={p.psid} person={p} />)}
    {isLoading && <PersonCard />}
  </ResponsiveColumnGrid>
);
