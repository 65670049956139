import React, { MouseEventHandler, useCallback } from 'react';
import { MoreVert, SvgIconComponent } from '@mui/icons-material';
import {
  Button,
  Dialog,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery
} from '@mui/material';
import { useI18n } from '../i18n/i18n';

interface PopupMenuProps extends IconButtonProps {
  buttonLabel?: string;
  Icon?: SvgIconComponent;
  items: { key: string | number; header: string; Icon: SvgIconComponent; action: () => void }[];
}

const MenuToggle: React.FC<{
  label?: string;
  Icon?: SvgIconComponent;
  onClick: MouseEventHandler<HTMLButtonElement>;
}> = ({ label, Icon, onClick, ...domAttributes }) => {
  if (label) {
    return (
      <Button {...domAttributes} onClick={onClick}>
        {label}
      </Button>
    );
  }
  return (
    <IconButton {...domAttributes} onClick={onClick}>
      {Icon ? <Icon /> : <MoreVert />}
    </IconButton>
  );
};

export const PopupMenu: React.FC<PopupMenuProps> = ({ buttonLabel, Icon, items, ...domAttributes }) => {
  const { i18n } = useI18n();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const close = useCallback(() => setAnchorEl(null), []);
  const isWidthLimited = useMediaQuery('(max-width: 600px)');
  return (
    <>
      <MenuToggle
        {...domAttributes}
        label={buttonLabel}
        onClick={(e) => setAnchorEl(e.target as Element)}
        Icon={Icon}
      />
      {isWidthLimited ? (
        <Dialog open={open} onClose={close} disableScrollLock>
          <List>
            {items.map(({ key, header, Icon, action }) => (
              <ListItem
                key={key}
                onClick={() => {
                  action();
                  close();
                }}
              >
                <ListItemIcon style={{ width: 0, padding: 0, margin: 0 }}>
                  <Icon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{header}</ListItemText>
              </ListItem>
            ))}
          </List>
          <Button onClick={close}>{i18n('action.close', 'Close')}</Button>
        </Dialog>
      ) : (
        <Menu anchorEl={anchorEl || undefined} open={open} onClose={close}>
          {items.map(({ key, header, Icon, action }) => (
            <MenuItem
              key={key}
              onClick={() => {
                action();
                close();
              }}
            >
              <ListItemIcon>
                <Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{header}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
