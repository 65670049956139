import * as dagre from 'dagre';
import { Node, Edge } from 'react-flow-renderer';
import { CompanyStructure, CompanyStructureEdge, CompanyStructureNode, Maybe } from '../../generated/graphql';

export type EdgeUpdateOwnershipPercentage = (owner: string, subsidiary: string, ownershipPercentage: string) => void;

export interface EdgeIndex {
  id: string;
  index: number;
  of: number;
}

export interface EdgeData {
  ownershipPct: string;
  updateOwnershipPct: (ownershipPercentage: string) => void;
  selfOwned: boolean;
}

export type FlowEdge = Edge<EdgeData>;

export interface NodeData {
  name: string;
  company: Maybe<CompanyStructureNode>;
  isTarget: boolean;
  sources: EdgeIndex[];
}

export type FlowNode = Node<NodeData>;

export type Graph = (FlowNode | FlowEdge)[];

class CachableEdge implements EdgeData {
  public ownershipPct: string;
  public readonly selfOwned: boolean;

  private readonly updateOwnerPercentage: EdgeUpdateOwnershipPercentage;
  private readonly owner: string;
  private readonly subsidiary: string;

  public constructor(
    ownershipPct: string,
    updatedOwnershipPct: string,
    owner: string,
    subsidiary: string,
    updateOwnerPercentage: EdgeUpdateOwnershipPercentage
  ) {
    this.ownershipPct = updatedOwnershipPct === '0' ? ownershipPct : updatedOwnershipPct;
    this.owner = owner;
    this.subsidiary = subsidiary;
    this.selfOwned = owner === subsidiary;
    this.updateOwnerPercentage = updateOwnerPercentage;
  }

  public updateOwnershipPct = (ownershipPercentage: string): void => {
    this.updateOwnerPercentage(this.owner, this.subsidiary, ownershipPercentage);
    this.ownershipPct = ownershipPercentage;
  };
}

function createDagreGraph(nodes: CompanyStructureNode[], edges: CompanyStructureEdge[]): dagre.graphlib.Graph {
  const dagreGraph = new dagre.graphlib.Graph({
    compound: true,
    multigraph: false
  });

  dagreGraph.setGraph({
    rankdir: 'TB',
    marginx: 20,
    marginy: 20,
    edgesep: 100,
    ranksep: 100,
    nodesep: 50,
    compound: true
  });

  dagreGraph.setDefaultEdgeLabel(function () {
    return {};
  });

  for (const node of nodes) {
    const dNode = {
      ...node,
      width: 250,
      height: node.type === 'Person' ? 20 : 180,
      id: node.internalId
    };

    dagreGraph.setNode(dNode.id, dNode);
  }

  for (const edge of edges) {
    dagreGraph.setEdge(edge.owner, edge.subsidiary, {
      ownershipPct: edge.ownershipPercentage,
      updatedOwnershipPct: edge.ownershipUpdatedPercentage,
      id: edge.owner
    });
  }

  return dagreGraph;
}

const toFlowNodes = (graph: dagre.graphlib.Graph, edges: CompanyStructureEdge[]): FlowNode[] => {
  const subsideries = new Set([...edges.map((e) => e.subsidiary)]);

  const nodeToEdges = new Map<string, (dagre.Edge & { x: number })[]>();

  for (const edge of graph.edges()) {
    const node = nodeToEdges.get(edge.v);
    const xEdge = { ...edge, x: graph.node(edge.w).x };
    if (node) {
      node.push(xEdge);
    } else {
      nodeToEdges.set(edge.v, [xEdge]);
    }
  }

  const nodes = graph.nodes().map((id: string) => {
    const { x, y, company, name, type, ...rest } = graph.node(id) as dagre.Node<CompanyStructureNode>;

    const nodeEdges = nodeToEdges.get(id);
    const sortedEdges = nodeEdges ? nodeEdges.sort((a, b) => a.x - b.x) : [];
    const sources = sortedEdges.map((e, index) => ({ index, of: sortedEdges.length, id: `${e.v}-${e.w}` }));

    return {
      id,
      type,
      position: { x, y },
      data: {
        type,
        company,
        name,
        isTarget: subsideries.has(id),
        sources
      },
      ...rest
    } as FlowNode;
  });
  return nodes;
};

const toFlowEdges = (
  updateOwnershipPercentage: EdgeUpdateOwnershipPercentage,
  graph: dagre.graphlib.Graph
): FlowEdge[] => {
  return graph.edges().map(({ v: owner, w: subsidiary }: { v: string; w: string }) => {
    const { ownershipPct, updatedOwnershipPct } = graph.edge(owner, subsidiary);
    return {
      data: new CachableEdge(ownershipPct, updatedOwnershipPct, owner, subsidiary, updateOwnershipPercentage),
      source: owner,
      target: subsidiary,
      id: `${owner}-${subsidiary}`,
      sourceHandle: `${owner}-${subsidiary}`,
      type: 'custom'
    };
  });
};

export function layoutDagreGraph(
  updateOwnershipPercentage: EdgeUpdateOwnershipPercentage,
  companyStructure: Maybe<CompanyStructure>
): Graph | undefined {
  const nodes = companyStructure?.nodes as CompanyStructureNode[];
  const edges = companyStructure?.edges as CompanyStructureEdge[];

  if (!nodes || !edges) return;

  const graph = createDagreGraph(nodes, edges);
  dagre.layout(graph);
  return [...toFlowNodes(graph, edges), ...toFlowEdges(updateOwnershipPercentage, graph)];
}
