import {
  CreditScore,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NotificationsActive,
  PriceCheck,
  EmojiEvents
} from '@mui/icons-material';
import { Card, CardContent, CardHeader, Icon, IconButton, List, ListItem, Typography } from '@mui/material';
import { amber, deepOrange, green, grey, lightGreen, lime, orange, red, teal, yellow } from '@mui/material/colors';
import { HTMLAttributes, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useI18n } from '../i18n/i18n';
import { getLeadColor } from './LeadGauge';

const heatRangePalette = [teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, red];

interface Rating {
  value: number;
  range: string[];
  neutralColor: string;
  breakdown?: { value: number; name: string }[];
}

interface CreditAnalysisCardProps extends HTMLAttributes<HTMLDivElement> {
  header: string;
  icon: JSX.Element;
  value: string;
  unit?: string;
  rating?: Rating;
  expandableContent?: JSX.Element;
}

const ValueContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const Value = styled.div`
  font-size: 1.8em;
  font-weight: 700;
`;

const Unit = styled(Typography)`
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
`;

const Content = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > * + * {
    margin-top: 8px;
  }
`;

const SlideOut = styled.div`
  border-top: 1px solid ${grey[200]};
  border-bottom: 1px solid ${grey[200]};
  display: flex;
  flex-direction: column;
`;

interface RatingRangeProps extends HTMLAttributes<HTMLDivElement> {
  size: number;
  value: number;
  range: string[];
  neutralColor: string;
  breakdown?: { value: number; name: string }[];
}

const RatingItem = styled.div<{ color: string; size: number }>`
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  background: ${(p) => p.color};
`;

const Range = styled.div`
  display: flex;
  flex-direction: row;
  > * + * {
    margin-left: 4px;
  }
`;

const RatingRange: React.FC<RatingRangeProps> = ({ value, range, neutralColor, size, ...domAttributes }) => {
  return (
    <Range {...domAttributes}>
      {range.map((color, index) => (
        <RatingItem key={index} color={index < value ? color : neutralColor} size={size} />
      ))}
    </Range>
  );
};

interface BreakdownProps extends HTMLAttributes<HTMLDivElement> {
  range: string[];
  neutralColor: string;
  breakdown: { value: number; name: string }[];
}

const BreakdownContainer = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 8px;

  > * + * {
    margin-top: 4px;
  }
`;

const BreakdownItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const Breakdown: React.FC<BreakdownProps> = ({ breakdown, range, neutralColor, ...domAttributes }) => {
  return (
    <BreakdownContainer {...domAttributes}>
      {breakdown.map(({ value, name }) => (
        <BreakdownItem key={name}>
          <RatingRange style={{ marginRight: 8 }} size={7} value={value} range={range} neutralColor={neutralColor} />
          <Typography variant="body2" sx={{ fontSize: '0.8em' }}>
            {name}
          </Typography>
        </BreakdownItem>
      ))}
    </BreakdownContainer>
  );
};

interface ExpanderWrapperProps {
  isExpanded: boolean;
  children: JSX.Element;
}

const ExpanderWrapper: React.FC<ExpanderWrapperProps> = ({ isExpanded, children }) => {
  return isExpanded ? (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', zIndex: 1, width: '100%' }}>{children}</div>
    </div>
  ) : (
    children
  );
};

const CreditAnalysisCard: React.FC<CreditAnalysisCardProps> = ({
  header,
  value,
  unit,
  icon,
  rating,
  expandableContent,
  ...domAttributes
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <ExpanderWrapper isExpanded={isExpanded}>
      <Card {...domAttributes}>
        <CardHeader
          title={header}
          titleTypographyProps={{ variant: 'h5', component: 'h3' }}
          avatar={<Icon>{icon}</Icon>}
          action={
            expandableContent && (
              <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )
          }
        />
        {isExpanded && <SlideOut>{expandableContent}</SlideOut>}
        <Content>
          <ValueContainer>
            <Value>{value}</Value>
            {unit && <Unit sx={{ color: 'text.seconday', marginLeft: 0.4 }}>{unit}</Unit>}
          </ValueContainer>
          <div>
            {rating && (
              <RatingRange size={15} value={rating.value} range={rating.range} neutralColor={rating.neutralColor} />
            )}
          </div>
        </Content>
      </Card>
    </ExpanderWrapper>
  );
};

export const CreditRatingCard: React.FC<{ rating: number; breakdown?: { name: string; value: number }[] }> = ({
  rating,
  breakdown
}) => {
  const { i18n } = useI18n();
  return (
    <CreditAnalysisCard
      header={i18n('credit_card.rating.title', 'Rating')}
      icon={<CreditScore />}
      value={rating ? rating.toFixed(0) : '-'}
      rating={{
        neutralColor: grey.A100,
        range: heatRangePalette.map((x) => x[400]),
        value: rating
      }}
      expandableContent={
        breakdown && (
          <Breakdown
            style={{ alignSelf: 'center' }}
            breakdown={breakdown}
            range={heatRangePalette.map((x) => x[400])}
            neutralColor={grey.A100}
          />
        )
      }
    />
  );
};

export const CreditExceptionsCard: React.FC<{ creditExceptions: string[] }> = ({ creditExceptions }) => {
  const { i18n } = useI18n();
  const creditRatings: Record<string, string> = useMemo(
    () => ({
      credit_flag_solvency: i18n(
        'credit_flag_solvency',
        'Egenkapitalen er mindre end 15% af selskabets samlede aktiver'
      ),
      credit_flag_leverage: i18n(
        'credit_flag_leverage',
        'Forholdet mellem nettogæld og EBITDA er over 8 (eller ukendt)'
      ),
      credit_flag_profitability: i18n('credit_flag_profitability', 'Selskabet har negativt nettoresultat'),
      credit_flag_liquidity: i18n('credit_flag_liquidity', 'Selskabet har negativ cash flow fra drift'),
      credit_flag_geography: i18n('credit_flag_geography', 'Selskabets hovedkvarter ligger uden for Danmark'),
      credit_flag_currency: i18n('credit_flag_currency', 'Regnskabet bruger en anden valuta end DKK'),
      credit_flag_status: i18n('credit_flag_status', 'Selskabet er ikke aktivt'),
      credit_flag_size: i18n('credit_flag_size', 'Summen af de samlede aktiver ligger uden for DKKm 1-250'),
      credit_flag_industry: i18n('credit_flag_industry', 'Selskabets industri ligger uden for de ønskede industrier'),
      credit_flag_legal_entity: i18n('credit_flag_legal_entity', 'Selskabet er ikke et ApS, A/S eller IVS'),
      credit_flag_reports: i18n('credit_flag_reports', 'Kompasbank har ikke de seneste 3 årsrapporter fra selskabet'),
      credit_flag_tardiness: i18n(
        'credit_flag_tardiness',
        'Offentliggørelsen sker over 6 måneder efter regnskabets afslutning'
      ),
      credit_flag_novelty: i18n(
        'credit_flag_novelty',
        'Regnskabets afslutning ligger mere end 18 måneder tilbage i tid'
      )
    }),
    [i18n]
  );

  return (
    <CreditAnalysisCard
      header={i18n('credit_card.exceptions.title', 'Exceptions')}
      icon={<NotificationsActive />}
      value={`${creditExceptions.length}/13`}
      rating={{
        neutralColor: grey.A100,
        range: [...Array(13)].map(() => grey[400]),
        value: creditExceptions.length
      }}
      expandableContent={
        creditExceptions.length > 0 ? (
          <List>
            {creditExceptions.map((exception) => (
              <ListItem key={exception}>{creditRatings[exception]}</ListItem>
            ))}
          </List>
        ) : undefined
      }
    />
  );
};

export const CreditMaxCard: React.FC<{ creditMax: number }> = ({ creditMax }) => {
  const { i18n } = useI18n();
  return (
    <CreditAnalysisCard
      header={i18n('credit_card.max.title', 'Max')}
      icon={<PriceCheck />}
      value={`${creditMax.toLocaleString()}`}
      unit="dkk"
    />
  );
};

export const LeadProbabilityCard: React.FC<{ probability: number }> = ({ probability }) => {
  const { i18n } = useI18n();
  return (
    <CreditAnalysisCard
      header={i18n('credit_card.lead_confidence.title', 'Lead confidence')}
      icon={<EmojiEvents />}
      value={(100 * probability).toFixed(0)}
      unit="%"
      rating={{
        neutralColor: grey.A100,
        range: [...Array(12)].map(() => getLeadColor(probability)),
        value: 12 * probability
      }}
    />
  );
};
