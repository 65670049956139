import { useQuery, UseQueryResult } from 'react-query';
import { CompanyDbInterestingOption } from '../generated/graphql';
import { InterestingOptionsKind, useApi } from './api';

const HOURS_24 = 24 * 60 * 60 * 1000;

const interestingOptionsKey = (kind: InterestingOptionsKind): string => `interesting-options-${kind}`;

export const useInterestingOptions = (
  kind: InterestingOptionsKind
): UseQueryResult<CompanyDbInterestingOption[], Error> => {
  const api = useApi();
  return useQuery<CompanyDbInterestingOption[], Error>(
    interestingOptionsKey(kind),
    () => api.options.getInterestingOptions(kind),
    { cacheTime: HOURS_24 }
  );
};
