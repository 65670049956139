import { Lightbulb } from '@mui/icons-material';
import { Card, CardHeader, Icon, Link } from '@mui/material';
import { HTMLAttributes } from 'react';
import { CompanyDbCompany } from '../generated/graphql';
import { useI18n } from '../i18n/i18n';
import { InfoCardContent, InfoCell, InfoLineWithSkeleton } from './layout';

interface CompanyInfoCardProps extends HTMLAttributes<HTMLDivElement> {
  company?: CompanyDbCompany;
}

export const CompanyInfoCard: React.FC<CompanyInfoCardProps> = ({ company, ...domAttributes }) => {
  const { i18n } = useI18n();
  return (
    <Card {...domAttributes}>
      <CardHeader
        title="Info"
        titleTypographyProps={{ variant: 'h5', component: 'h3' }}
        avatar={
          <Icon>
            <Lightbulb />
          </Icon>
        }
      />
      <InfoCardContent>
        <InfoCell>
          <InfoLineWithSkeleton entity={company} width={300}>
            {(company) =>
              i18n('company_card.employee_count', 'Number of employees: {employeeCount}', {
                employeeCount: company.employees_number || 0
              })
            }
          </InfoLineWithSkeleton>
          <InfoLineWithSkeleton entity={company}>
            {(company) => `Founded: ${company.founded || '-'}`}
          </InfoLineWithSkeleton>
          <InfoLineWithSkeleton entity={company}>
            {(company) => (
              <>
                {i18n('company_card.company_registry_id', 'Company registry id: {id}', {
                  id: company.company_registry_id
                })}
                {
                  <>
                    (
                    <Link
                      target="_blank"
                      rel="noreferer"
                      href={`https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${company.company_registry_id}`}
                    >
                      {i18n('company_card.company:registry', 'virk')}
                    </Link>
                    )
                  </>
                }
              </>
            )}
          </InfoLineWithSkeleton>
        </InfoCell>
        <InfoCell>
          <InfoLineWithSkeleton entity={company}>
            {() => i18n('company_card.signing_authority', 'Signing authority:')}
          </InfoLineWithSkeleton>
          <InfoLineWithSkeleton entity={company} canWrap width={300}>
            {(company) => company.authority_to_sign}
          </InfoLineWithSkeleton>
        </InfoCell>
      </InfoCardContent>
    </Card>
  );
};
