import React from 'react';
import { useCompanies } from '../api/company';
import { Filter } from '../generated/graphql';
import { CompanyList } from '../components/companyList';
import { H1 } from '../components/layout';
import { useI18n } from '../i18n/i18n';

const loadingFavorites = [...Array(5)].map((_, i) => ({ key: i, favorite: true }));

export const FavouritesPage: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const { i18n } = useI18n();
  const favorites = useCompanies(Filter.Favorites);

  return (
    <div>
      <H1>{i18n('pages.favorites.header', 'Favorites')}</H1>
      <CompanyList
        companies={(!favorites.isLoading && favorites.data) || []}
        loadingCompanies={favorites.isLoading ? loadingFavorites : undefined}
      />
    </div>
  );
};
