import React from 'react';
import { useCompanies } from '../api/company';
import { Filter } from '../generated/graphql';
import { CompanyList } from '../components/companyList';
import { H1 } from '../components/layout';
import { useI18n } from '../i18n/i18n';

const loadingPortfolio = [...Array(5)].map((_, i) => ({ key: i, favorite: true }));

export const PortfolioPage: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const { i18n } = useI18n();
  const portfolio = useCompanies(Filter.Portfolio);

  return (
    <div>
      <H1>{i18n('pages.portfolio.header', 'Portfolio')}</H1>
      <CompanyList
        companies={(!portfolio.isLoading && portfolio.data) || []}
        loadingCompanies={portfolio.isLoading ? loadingPortfolio : undefined}
      />
    </div>
  );
};
