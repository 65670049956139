import { colors } from '@mui/material';
import React, { SVGProps } from 'react';

const colorScale = 500;

export const getLeadColor = (value: number): string => {
  if (value <= 0.25) return colors.red[colorScale];
  if (value <= 0.5) return colors.orange[colorScale];
  if (value <= 0.75) return colors.yellow[colorScale];
  return colors.green[colorScale];
};

export const LeadGauge: React.FC<{ probability: number } & SVGProps<SVGSVGElement>> = ({
  probability,
  ...domAttributes
}) => {
  const size = 30;
  return (
    <svg {...domAttributes} viewBox={`0 0 ${size} ${size}`}>
      <text y="50%" fontSize={(2 * size) / 5}>
        {(100 * probability).toFixed(0)}%
      </text>
      <rect x="0" y="56%" fill={colors.blueGrey.A100} height="5" width={size} />
      <rect x="0" y="56%" fill={getLeadColor(probability)} height="5" width={probability * size} />
    </svg>
  );
};
