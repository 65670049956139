import { useState, useEffect } from 'react';
import { H1, H2 } from '../components/layout';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { useUpdateUserProfile, useUserProfile } from '../api/user';
import { regions } from '../api/api';
import { TextArea } from '../components/Form/formInput';
import { Locale, useI18n } from '../i18n/i18n';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { cachedLocale } from '../api/userProfile';

export const Profile: React.FC = () => {
  const { i18n } = useI18n();
  const currentProfile = useUserProfile();
  const updateUserProfile = useUpdateUserProfile();
  const [selectedRegion, setSelectedRegion] = useState(parseInt(currentProfile.data?.region || '0'));
  const [locale, setLocale] = useState(currentProfile.data?.locale || cachedLocale());
  const [homeAddress, setHomeAddress] = useState(currentProfile.data?.homeAddress);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRegion(parseInt(event.target.value));
  };

  useEffect(() => {
    return () => {
      // store on unmount
      updateUserProfile.mutateAsync({ region: selectedRegion.toFixed(0), homeAddress, locale });
    };
  }, [selectedRegion, homeAddress, locale]);

  return (
    <div>
      <H1>{i18n('profile.header', 'Profile')}</H1>
      <Typography sx={{ color: '#fff' }}>{currentProfile.data?.name}</Typography>
      <Paper style={{ padding: 16 }}>
        <FormControl>
          <InputLabel id="lanugage-switcher">{i18n('profile.language', 'Language')}</InputLabel>
          <Select
            labelId="lanugage-switcher"
            id="language-switcher-select"
            value={locale}
            label={i18n('language_switch.label', 'Language')}
            onChange={(e) => setLocale(e.target.value as Locale)}
          >
            <MenuItem value={'en-US'}>{i18n('languages.en-US', 'English')}</MenuItem>
            <MenuItem value={'da-DK'}>{i18n('languages.da-DK', 'Danish')}</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <H2>{i18n('location.region', 'Region')}</H2>
      <Paper sx={{ padding: 2 }}>
        <Typography gutterBottom variant="h6" component="div">
          {i18n('profile.select_region', 'Select region option:')}
        </Typography>
        <CardActions>
          <FormControl component="fieldset">
            <RadioGroup row name="region-options" onChange={onChange}>
              {regions.map((region) => (
                <FormControlLabel
                  checked={selectedRegion === region.id}
                  key={region.id}
                  value={region.id}
                  control={<Radio />}
                  label={region.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </CardActions>
      </Paper>
      <H2>{i18n('location.address', 'Address')}</H2>
      <Paper sx={{ padding: 2 }}>
        <TextArea
          style={{ width: '100%' }}
          defaultValue={homeAddress}
          onBlur={(x) => {
            setHomeAddress(x.target.value);
          }}
        />
      </Paper>
    </div>
  );
};
