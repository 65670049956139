import React, { useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  useRoutes,
  useLocation,
  RouteObject,
  useParams,
  generatePath
} from 'react-router-dom';
import styled from 'styled-components';
import { AuthBoundary } from './AuthBoundary';
import { H1 } from './layout';
import { Topbar } from './topbar/topbar';
import { useRelativeRoutes } from './topbar/topbarContext';

const NotFound: React.FC = () => <H1>Not found :(</H1>;

const Main = styled.main`
  flex: 1;
  padding: 10px;
  margin-top: 20px;
`;
export interface RouteProps {
  header: string;
  menuEntry?: 'always' | 'relative' | 'never';
  icon: JSX.Element;
  path: string;
  auth?: boolean;
  pageComponent: JSX.Element;
  nestedRoutes?: RouteProps[];
}
interface NavigationProps extends React.HTMLAttributes<HTMLDivElement> {
  routes: RouteProps[];
}

const WithRelativeRoutes: React.FC<{ routes: RouteProps[]; parents: string[]; children: JSX.Element }> = ({
  routes,
  parents,
  children
}) => {
  const params = useParams();
  const relativeRoutes = routes
    .filter((x) => x.menuEntry === 'relative' || x.menuEntry === 'always')
    .map(({ path, header, icon }) => ({
      path: generatePath([...parents.map((p) => p.replace(/^\//, '')), path].join('/'), params),
      header,
      icon
    }));
  useRelativeRoutes(relativeRoutes);

  return children;
};

const HandleRelativeRoutes: React.FC<{ route: RouteProps; parents: string[] }> = ({ route, parents }) => {
  if (route.nestedRoutes) {
    return (
      <WithRelativeRoutes routes={route.nestedRoutes} parents={parents}>
        {route.pageComponent}
      </WithRelativeRoutes>
    );
  } else if (route.menuEntry === 'relative') {
    return (
      <WithRelativeRoutes routes={[route]} parents={[]}>
        {route.pageComponent}
      </WithRelativeRoutes>
    );
  }
  return route.pageComponent;
};

const ScrollRestauration: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { pathname } = useLocation();
  const ref = useRef<string[]>(['', '']);

  // React retain scroll position. We however only want to do that, if we
  // are navigating to page and back again.
  useEffect(() => {
    const [secondLastPath, lastPath] = ref.current;
    if (pathname !== secondLastPath) {
      scrollTo(0, 0);
    }
    ref.current = [lastPath, pathname];
  });

  return children;
};

const WithAuth: React.FC<{ auth?: boolean; children: JSX.Element }> = ({ auth, children }) => {
  if (auth) {
    return <AuthBoundary>{children}</AuthBoundary>;
  }
  return children;
};

const toRouteObject = ({ parents, ...route }: RouteProps & { parents?: string[] }): RouteObject => {
  const parentsAndSelf = [...(parents || []), route.path];
  return {
    path: route.path,
    element: (
      <WithAuth auth={route.auth}>
        <ScrollRestauration>
          <HandleRelativeRoutes route={route} parents={parentsAndSelf} />
        </ScrollRestauration>
      </WithAuth>
    ),
    children: route.nestedRoutes?.map((nested) => toRouteObject({ ...nested, parents: parentsAndSelf }))
  };
};

const Routes: React.FC<{ routes: RouteProps[] }> = ({ routes }) =>
  useRoutes([...routes.map(toRouteObject), { path: '*', element: <NotFound /> }]);

export const Navigation: React.FC<NavigationProps> = ({ routes, ...domAttributes }) => (
  <Router>
    <div {...domAttributes}>
      <Topbar routes={routes} />
      <Main>
        <Routes routes={routes} />
      </Main>
    </div>
  </Router>
);
