import { FollowUp } from '../components/followUp';
import { H1, H2 } from '../components/layout';
import { LeadsGrid } from '../components/leads-grid';
import { useI18n } from '../i18n/i18n';

export const Dashboard: React.FC = () => {
  const { i18n } = useI18n();
  return (
    <div>
      <H1>{i18n('home.header', 'My Dashboard')}</H1>
      <H2>{i18n('home.follow_up', 'Follow up')}</H2>
      <FollowUp />
      <LeadsGrid />
    </div>
  );
};
