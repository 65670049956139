import { Button } from '@mui/material';
import { InterestingOptionsKind } from '../../api/api';
import { FormEvent, FormEventHandler, HTMLAttributes } from 'react';

import { H2 } from '../layout';
import { Form, FormButtonContainer } from './formInput';
import { CompanyDbInteresting } from '../../generated/graphql';
import { useI18n } from '../../i18n/i18n';

interface PopupFormProps extends HTMLAttributes<HTMLDivElement> {
  header: string;
  submit: FormEventHandler<HTMLFormElement>;
  onCancel: () => void;
  onUnset?: () => Promise<void>;
  formProps?: HTMLAttributes<HTMLFormElement>;
  interesting?: CompanyDbInteresting;
  mode?: InterestingOptionsKind;
  companyId?: number;
}

export function submitValueHelper<T>(e: FormEvent<HTMLFormElement>): T {
  return Object.values(e.target)
    .filter((i) => i.name)
    .reduce((acc, next) => {
      acc[next.name] = next.value;
      return acc;
    }, {} as T);
}

export const PopupForm: React.FC<PopupFormProps> = ({
  header,
  submit,
  mode,
  companyId,
  onCancel,
  onUnset,
  formProps,
  interesting,
  children,
  ...domAttributes
}) => {
  const { i18n } = useI18n();
  return (
    <div {...domAttributes}>
      <H2 isEmbedded style={{ paddingTop: 0 }}>
        {header}
      </H2>
      <Form {...formProps} onSubmit={submit}>
        {children}
        <FormButtonContainer>
          <Button type="button" variant="outlined" onClick={onCancel}>
            {i18n('action.cancel', 'Cancel')}
          </Button>
          {((interesting?.interesting === true && mode === 'interesting') ||
            (interesting?.interesting === false && mode === 'not-interesting')) && (
            <Button type="button" variant="outlined" color="error" onClick={onUnset}>
              {i18n('action.unset', 'Unset')}
            </Button>
          )}
          <Button type="submit" variant="contained">
            {i18n('action.ok', 'OK')}
          </Button>
        </FormButtonContainer>
      </Form>
    </div>
  );
};
