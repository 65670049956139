import { CardContent, Typography } from '@mui/material';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { CssLength, SkeletonElement } from './skeletonElement';

export const topbarBreakWidth = 800;

export const gridGap = '20px';

export const kompas = {
  colors: {
    red: '#ff6666',
    blue: '#330066'
  }
};

export const InfoCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;

  > * + * {
    margin-left: ${gridGap};
  }

  @media (max-width: 600px) {
    flex-direction: column;

    > * + * {
      margin-left: 0;
      margin-top: ${gridGap};
    }
  }
`;

export interface InfoLineProps {
  canWrap?: boolean;
}

export const InfoLine = styled.p<InfoLineProps>`
  margin: 0;
  white-space: ${(p) => (p.canWrap ? 'normal' : 'nowrap')};
`;

export const InfoCell = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 6px;
  }
`;

interface InfoLineWithSkeletonProps<T> extends InfoLineProps {
  width?: CssLength;
  entity?: T;
  children: (entity: T) => React.ReactNode;
}

export function InfoLineWithSkeleton<T>({
  width,
  entity,
  children,
  ...domAttributes
}: InfoLineWithSkeletonProps<T>): JSX.Element {
  return (
    <InfoLine {...domAttributes}>
      {entity ? <>{children(entity)}</> : <SkeletonElement width={width || 200} />}
    </InfoLine>
  );
}

export const ColumnGrid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: ${gridGap};
`;

export const ResponsiveColumnGrid = styled.div<{ count: number; itemMinWidth?: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${(p) => (p.count === 1 ? 'auto-fill' : 'auto-fit')},
    minmax(${(p) => p.itemMinWidth || 240}px, 1fr)
  );
  gap: ${gridGap};
`;

export interface HeaderProps extends HTMLAttributes<HTMLHeadElement> {
  isEmbedded?: boolean;
}

function getHeaderStyling(isEmbedded?: boolean, style?: React.CSSProperties): React.CSSProperties {
  style = { padding: '32px 0 16px 0', ...style };
  if (!isEmbedded) {
    style.color = '#fff';
  }
  return style;
}

export const H1: React.FC<HeaderProps> = ({ isEmbedded, style, ...domAttributes }) => (
  <Typography {...domAttributes} style={getHeaderStyling(isEmbedded, style)} component="h1" variant="h3" />
);

export const H2: React.FC<HeaderProps> = ({ isEmbedded, style, ...domAttributes }) => (
  <Typography {...domAttributes} style={getHeaderStyling(isEmbedded, style)} component="h2" variant="h4" />
);

export const H3: React.FC<HeaderProps> = ({ isEmbedded, style, ...domAttributes }) => (
  <Typography
    {...domAttributes}
    style={{ padding: '16px 0 8px 0', ...getHeaderStyling(isEmbedded, style) }}
    component="h3"
    variant="h5"
  />
);
