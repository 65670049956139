import { DialogContent, Dialog } from '@mui/material';
import { HTMLAttributes } from 'react';
import { InterestingOptionsKind } from '../api/api';
import { useInterestingOptions } from '../api/options';
import { CompanyDbInteresting } from '../generated/graphql';
import { useI18n } from '../i18n/i18n';
import { InterestingForm } from './interestingForm';

interface InterestingModalProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  onSetInteresting: (value: { comment: string; optionIds: string[] }) => Promise<void>;
  isOpen: boolean;
  mode: InterestingOptionsKind;
  interesting?: CompanyDbInteresting;
  companyId: string;
}

const getCurrentInterest = (
  mode: InterestingOptionsKind,
  interesting?: CompanyDbInteresting
): CompanyDbInteresting | undefined => {
  switch (mode) {
    case 'interesting':
      return interesting?.interesting ? interesting : undefined;
    case 'not-interesting':
      return interesting?.interesting === false ? interesting : undefined;
  }
};

export const InterestingModal: React.FC<InterestingModalProps> = ({
  mode,
  companyId,
  isOpen,
  onClose,
  interesting,
  onSetInteresting
}) => {
  const { i18n } = useI18n();
  const currentInterest = getCurrentInterest(mode, interesting);
  const interestingOptions = useInterestingOptions(mode);

  const optionTypes = (!interestingOptions.isLoading && interestingOptions.data) || [];
  const options = optionTypes.map((o) => ({
    id: o.option_id || '',
    name: o.description || '',
    isSelected: !!o.option_id && !!currentInterest?.interesting_option_ids?.includes(o.option_id)
  }));

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <InterestingForm
          onCancel={onClose}
          comment={currentInterest?.comment || ''}
          header={
            mode === 'interesting'
              ? i18n('interesting_modal.interested', 'Interested?')
              : i18n('interesting_modal.not_interested', 'Not interested?')
          }
          mode={mode}
          companyId={companyId}
          options={options}
          interesting={interesting}
          onStore={async (value) => {
            await onSetInteresting(value);
            onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
