import { GraphQLClient, gql, RequestDocument } from 'graphql-request';
import { apiEndpoint, apiKey } from '../environment';
import {
  CompanyDbCompany,
  CompanyDbInterestingOption,
  CompanyReport,
  CompanyStructure,
  FieldDataInput,
  Filter,
  ReportType,
  PersonalRelation,
  Activity,
  Profile,
  Financials
} from '../generated/graphql';
import { Api, InterestingOptionsKind } from './api';
import { AuthError, getAuthToken } from './auth';
import { cachedLocale } from './userProfile';

const RETRY_LIMIT = 12;
const RETRY_BACKOFF_MS = 3000;

const getClient = (signal?: AbortSignal, requestHeaders?: Record<string, string>): GraphQLClient =>
  new GraphQLClient(apiEndpoint, {
    mode: 'cors',
    headers: requestHeaders,
    signal
  });

const listCompaniesQuery = gql`
  query listCompanies($filter: Filter, $search: String, $region?: Int, $nameSearch: String) {
    listCompanies(limit: 50, filter: $filter, search: $search, region: $region, nameSearch: $nameSearch) {
      name
      cid
      status
      address_region
      probability
      industry_domestic_name
      favorite
      rating
      credit_flags_sum
      max_loan_amount
    }
  }
`;

const getRelationsQuery = gql`
  query getRelations($psid: String!) {
    getRelations(psid: $psid) {
      name
      role
      period_begin
      company {
        name
        cid
        rating
        credit_flags_sum
        max_loan_amount
      }
    }
  }
`;

const getFollowUpQuery = gql`
  query getFollowUp {
    getFollowUp {
      activity_id
      note
      follow_up_date
      cid
      updated
      company {
        name
      }
    }
  }
`;

const getProfileQuery = gql`
  query getProfile {
    getProfile {
      name
      email
      region
    }
  }
`;

const updateProfileMutation = gql`
  mutation updateProfile($email: String, $region: String) {
    updateProfile(email: $email, region: $region)
  }
`;

const getCompanyQuery = gql`
  query getCompany($cid: String!) {
    getCompany(cid: $cid) {
      name
      cid
      advertising_protection
      favorite
      company_registry_id
      contact_email
      address_co_name
      address_street_name
      address_street_number
      address_floor
      address_postal_code
      address_region
      address_country
      address_town
      employees_number
      contact_phone_number
      founded
      website
      authority_to_sign
      rating
      equity_ratio_score
      net_debt_to_ebitda_score
      net_debt_to_cffo_score
      return_on_equity_score
      credit_flag_currency
      credit_flag_geography
      credit_flag_industry
      credit_flag_legal_entity
      credit_flag_leverage
      credit_flag_liquidity
      credit_flag_novelty
      credit_flag_profitability
      credit_flag_reports
      credit_flag_size
      credit_flag_solvency
      credit_flag_status
      credit_flag_tardiness
      credit_flags_sum
      max_loan_amount
      probability
      ncino
      interesting {
        interesting
        interesting_option_ids
        comment
      }
      directors {
        name
        role
        connections
        period_begin
        psid
      }
      board_of_directors {
        name
        role
        connections
        period_begin
        psid
      }
    }
  }
`;

const getCompanyReportQuery = gql`
  query getCompanyReport($cid: String!, $reportType: ReportType!) {
    getCompanyReport(cid: $cid, report: $reportType) {
      actions
      periods
      rows {
        label
        headline
        columns
        field
      }
    }
  }
`;

const getFinancialsQuery = gql`
  query getFinancials($cid: String!, $holding: Boolean, $reportType: ReportType!, $simulate: Boolean) {
    getFinancials(cid: $cid, holding: $holding, report: $reportType, simulate: $simulate) {
      headers
      ids
      simulation
      rows {
        label
        field
        headline
        editable
        values
        marks
        type
      }
    }
  }
`;

const addSimulationQuery = gql`
  mutation addSimulation($cid: String!, $holding: Boolean!) {
    addSimulation(cid: $cid, holding: $holding)
  }
`;
const updateSimulationQuery = gql`
  mutation updateSimulation(
    $cid: String!
    $holding: Boolean!
    $id: String!
    $field: String!
    $value: Int
    $note: String
  ) {
    updateSimulation(cid: $cid, holding: $holding, id: $id, field: $field, value: $value, note: $note)
  }
`;
const deleteSimulationQuery = gql`
  mutation deleteSimulation($cid: String!, $holding: Boolean!, $id: String!) {
    deleteSimulation(cid: $cid, holding: $holding, id: $id)
  }
`;

const setFavorite = gql`
  mutation setFavorite($cid: String!, $favorite: Boolean!) {
    setFavorite(cid: $cid, favorite: $favorite)
  }
`;

const getCompanyStructureQuery = gql`
  query getCompanyStructure($cid: String!) {
    getCompanyStructure(cid: $cid) {
      nodes {
        internalId
        name
        type
        company {
          cid
          name
          company_registry_id
          address_region
          founded
          rating
          credit_flags_sum
          max_loan_amount
          key_figures {
            net_debt
            ebitda
            net_debt_to_cffo
            net_debt_to_ebitda
            return_on_equity
            adjusted_equity
            equity_ratio
          }
        }
      }
      edges {
        owner
        subsidiary
        ownershipPercentage
        ownershipUpdatedPercentage
        type
        startDate
        ownerCountry
        ownerName
      }
    }
  }
`;

const setInterestingMutation = gql`
  mutation setInteresting($cid: String!, $interesting: Boolean!, $comment: String!, $interestingOptionIds: String!) {
    setInteresting(
      cid: $cid
      interesting: $interesting
      comment: $comment
      interesting_option_ids: $interestingOptionIds
    )
  }
`;

const unsetInterestingMutation = gql`
  mutation unSetInteresting($cid: String!) {
    unSetInteresting(cid: $cid)
  }
`;

const getActivitiesQuery = gql`
  query getActivities($cid: String!) {
    getActivities(cid: $cid) {
      activity_id
      created
      updated
      type
      note
      ref
      follow_up_date
      follow_up_done
      name
    }
  }
`;

const addActivityMutation = gql`
  mutation addActivity($cid: String!, $type: ActivityType!, $note: String!, $followUpDate: String!, $ref: String!) {
    addActivity(cid: $cid, type: $type, note: $note, follow_up_date: $followUpDate, ref: $ref) {
      activity_id
      created
      updated
      type
      note
      ref
      follow_up_date
      follow_up_done
      name
    }
  }
`;

const updateActivityMutation = gql`
  mutation updateActivity($cid: String!, $activityId: String!, $note: String!, $followUpDate: String!) {
    updateActivity(cid: $cid, activity_id: $activityId, note: $note, follow_up_date: $followUpDate)
  }
`;

const deleteActivityMutation = gql`
  mutation deleteActivity($cid: String!, $activityId: String!) {
    deleteActivity(cid: $cid, activity_id: $activityId)
  }
`;

const markActivityDoneMutation = gql`
  mutation markActivityDone($cid: String!, $activityId: String!) {
    markActivityDone(cid: $cid, activity_id: $activityId)
  }
`;

const transferToCrmMutation = gql`
  mutation uploadCompany($cid: String!) {
    uploadCompany(cid: $cid)
  }
`;

const getInterestingOptionsQuery = gql`
  query getInterestingOptions($interesting: Boolean) {
    getInterestingOptions(interesting: $interesting) {
      description
      option_id
    }
  }
`;

const saveFinancialPeriodMutation = gql`
  mutation saveFinancialPeriod($cid: String!, $period: String!, $fields: [FieldDataInput]!) {
    saveFinancialPeriod(cid: $cid, period: $period, fields: $fields)
  }
`;

const deleteFinancialPeriodMutation = gql`
  mutation deleteFinancialPeriod($cid: String!, $period: String!) {
    deleteFinancialPeriod(cid: $cid, period: $period)
  }
`;

const updateOwnershipPercentage = gql`
  mutation CompanyDBUpdate($owner: String!, $subsidiary: String!, $ownershipPercentage: String) {
    updateOwnershipPercentage(owner: $owner, subsidiary: $subsidiary, ownershipPercentage: $ownershipPercentage)
  }
`;

const isSleeping = (e: Error): boolean => e.message.includes('Communications link failure');

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getAuthHeader(): { Authorization: string } | { 'X-API-KEY': string } {
  if (apiKey) {
    return { 'X-API-KEY': apiKey };
  }
  const token = getAuthToken();
  if (token) {
    return {
      Authorization: token
    };
  }
  throw new AuthError('Not authorized');
}

async function requestWithRetry<T>(
  request: (client: GraphQLClient) => Promise<T>,
  signal?: AbortSignal,
  requestHeaders?: Record<string, string>
): Promise<T> {
  const client = getClient(signal, { ...requestHeaders, ...getAuthHeader(), 'accept-language': cachedLocale() });
  for (let i = 0; i < RETRY_LIMIT; i++) {
    try {
      return await request(client);
    } catch (e) {
      const error = e as Error & { response?: { errors: { errorType: string }[] } };
      if (isSleeping(error)) {
        await sleep(RETRY_BACKOFF_MS);
      } else if (error.response?.errors && error.response?.errors[0]?.errorType === 'UnauthorizedException') {
        throw new AuthError('Auth time out');
      } else {
        throw e;
      }
    }
  }
  throw new Error('Looks like the database is sleeping forever');
}

async function request<T>(
  document: RequestDocument,
  variables?: Record<string, unknown>,
  requestHeaders?: Record<string, string>,
  signal?: AbortSignal
): Promise<T> {
  return requestWithRetry((client) => client.request(document, variables), signal, requestHeaders);
}

function coerceSearch(search: string): string {
  search = search
    .trim()
    .replace(/\//g, 'Ф')
    .replace(/\,/g, 'ҫ')
    .replace(/\./g, 'ṕ')
    .split(/\s+/)
    .map((item) => (item.length > 2 ? '+' + item + '*' : item + '*'))
    .join(' ');

  return search;
}

export const graphqlApi: Api = {
  user: {
    getFollowUps: async () => {
      const { getFollowUp } = await request(getFollowUpQuery);
      return getFollowUp;
    },
    getProfile: async (): Promise<Profile> => {
      const { getProfile } = await request(getProfileQuery);
      return getProfile;
    },
    updateProfile: async (profile: Profile): Promise<void> => {
      await request(updateProfileMutation, profile);
    }
  },
  company: {
    list: async function (filter?: Filter, region?: number): Promise<CompanyDbCompany[]> {
      const { listCompanies } = await request(listCompaniesQuery, { filter, region });
      return listCompanies;
    },
    getRelations: async function (psid: string): Promise<PersonalRelation[]> {
      const { getRelations } = await request(getRelationsQuery, { psid });
      return getRelations;
    },
    search: async function (search: string, signal?: AbortSignal): Promise<CompanyDbCompany[]> {
      const { listCompanies } = await request(
        listCompaniesQuery,
        { nameSearch: coerceSearch(search) },
        undefined,
        signal
      );
      return listCompanies;
    },
    get: async function (cid: string): Promise<CompanyDbCompany> {
      const { getCompany } = await request(getCompanyQuery, { cid });
      return getCompany;
    },
    getCompanyStructure: async function (cid: string): Promise<CompanyStructure> {
      const { getCompanyStructure } = await request(getCompanyStructureQuery, { cid });
      return getCompanyStructure;
    },
    updateOwnershipPercentage: async function (
      owner: string,
      subsidiary: string,
      ownershipPercentage: string
    ): Promise<void> {
      await request(updateOwnershipPercentage, { owner, subsidiary, ownershipPercentage });
    },
    getCompanyReport: async function (cid: string, reportType: ReportType): Promise<CompanyReport> {
      const { getCompanyReport } = await request(getCompanyReportQuery, { cid, reportType });
      return getCompanyReport;
    },
    setFavourite: async function (cid: string, isFavorite: boolean): Promise<void> {
      await request(setFavorite, { cid, favorite: isFavorite });
    },
    setInteresting: async function (
      cid: string,
      interesting: boolean,
      comment: string,
      interestingOptionIds: string
    ): Promise<void> {
      return await request(setInterestingMutation, {
        cid,
        interesting,
        comment,
        interestingOptionIds
      });
    },
    unsetInteresting: async function (cid: string): Promise<void> {
      return await request(unsetInterestingMutation, {
        cid
      });
    },
    getActivities: async function (cid: string): Promise<Activity[]> {
      const { getActivities } = await request(getActivitiesQuery, { cid });
      return getActivities;
    },
    addActivity: async function (cid: string, type: string, note: string, followUpDate?: string): Promise<Activity> {
      const { addActivity } = await request(addActivityMutation, {
        cid,
        type,
        note,
        followUpDate: followUpDate || '',
        ref: ''
      });
      return addActivity;
    },
    updateActivity: async function (
      cid: string,
      activityId: string,
      note: string,
      followUpDate?: string
    ): Promise<void> {
      await request(updateActivityMutation, { cid, activityId, note, followUpDate: followUpDate || '' });
    },
    deleteActivity: async function (cid: string, activityId: string): Promise<void> {
      await request(deleteActivityMutation, { cid, activityId });
    },
    markActivityDone: async function (cid: string, activityId: string): Promise<void> {
      await request(markActivityDoneMutation, { cid, activityId });
    },
    saveFinancialPeriod: async function (cid: string, period: string, fields: FieldDataInput[]): Promise<void> {
      await request(saveFinancialPeriodMutation, { cid, period, fields });
    },
    deleteFinancialPeriod: async function (cid: string, period: string): Promise<void> {
      await request(deleteFinancialPeriodMutation, { cid, period });
    },
    transferToCrm: async function (cid: string): Promise<void> {
      await request(transferToCrmMutation, { cid });
    },
    getFinancials: async function (
      cid: string,
      holding: boolean,
      reportType: ReportType,
      simulate: boolean
    ): Promise<Financials> {
      const { getFinancials } = await request(getFinancialsQuery, { cid, holding, reportType, simulate });
      return getFinancials;
    },
    addSimulation: async function (cid: string, holding: boolean): Promise<void> {
      await request(addSimulationQuery, { cid, holding });
    },
    updateSimulation: async function (
      cid: string,
      holding: boolean,
      id: string,
      field: string,
      value: number,
      note: string
    ): Promise<void> {
      await request(updateSimulationQuery, { cid, holding, id, field, value, note });
    },
    deleteSimulation: async function (cid: string, holding: boolean, id: string): Promise<void> {
      await request(deleteSimulationQuery, { cid, holding, id });
    }
  },
  options: {
    getInterestingOptions: async function (kind: InterestingOptionsKind): Promise<CompanyDbInterestingOption[]> {
      const { getInterestingOptions } = await request(getInterestingOptionsQuery, {
        interesting: kind === 'interesting'
      });
      return getInterestingOptions;
    }
  }
};
