// import React from 'react';
import { Check, Edit } from '@mui/icons-material';
import { IconButton, colors } from '@mui/material';
import { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { getBezierPath, getMarkerEnd, EdgeProps } from 'react-flow-renderer';
import styled from 'styled-components';
import { EdgeData } from './dagreGraph';

const Input = styled.input`
  width: 30px;
  border: none;
  padding: 0;
  margin-top: 1px;
  font-size: 12px;
  font-weight: 400;
  text-align: right;

  :focus {
    outline: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }
`;

interface FormOrChildrenProps extends HTMLAttributes<HTMLFormElement> {
  isForm: boolean;
}

const FormOrChildren: React.FC<FormOrChildrenProps> = ({ isForm, children, ...formProps }) =>
  isForm ? <form {...formProps}>{children}</form> : <>{children}</>;

export function LabelledEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
  selected
}: EdgeProps): JSX.Element {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [isEditing, setIsEditing] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.select();
    }
  }, [isEditing]);

  const { ownershipPct, updateOwnershipPct, selfOwned } = data as EdgeData;

  const onUpdateOwnershipPercentage = useCallback((e) => {
    e.preventDefault();
    const value = e.target.ownershipPercentage.value;
    updateOwnershipPct(value);
    setIsEditing(false);
  }, []);

  const translateX = -16;

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />

      <foreignObject x={sourceX} y={sourceY} width="60" height="34" transform={`translate(${translateX} -4)`}>
        <FormOrChildren isForm={isEditing} onSubmit={onUpdateOwnershipPercentage}>
          <div
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
              fontWeight: 400,
              color: selfOwned ? colors.blue[200] : 'inherit'
            }}
          >
            {isEditing ? (
              <Input ref={inputRef} type="number" name="ownershipPercentage" defaultValue={ownershipPct} />
            ) : (
              <span style={{ background: '#fff', width: 30, textAlign: 'right' }}>{ownershipPct}</span>
            )}
            <span style={{ background: '#fff' }}>%</span>
            <IconButton
              style={{ opacity: selected || isEditing || isMouseOver ? 1 : 0 }}
              size="small"
              type={isEditing ? 'submit' : 'button'}
              onClick={isEditing ? undefined : () => setIsEditing(!isEditing)}
            >
              {isEditing ? <Check fontSize="small" /> : <Edit fontSize="small" />}
            </IconButton>
          </div>
        </FormOrChildren>
      </foreignObject>
    </>
  );
}
