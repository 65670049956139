import { Checkbox } from '@mui/material';
import { FormEvent, HTMLAttributes, useCallback } from 'react';
import { CheckboxLabel, TextArea, OverControlLabel } from './Form/formInput';
import { PopupForm } from './Form/popupForm';
import { CompanyDbInteresting } from '../generated/graphql';
import { InterestingOptionsKind } from '../api/api';
import { useUnsetInteresting } from '../api/company';
import styled from 'styled-components';
import { useI18n } from '../i18n/i18n';

interface Option {
  id: string;
  name: string;
  isSelected: boolean;
}

interface InterestingFormProps extends HTMLAttributes<HTMLDivElement> {
  header: string;
  options: Option[];
  comment?: string;
  mode?: InterestingOptionsKind;
  companyId: string;
  onStore: (value: { comment: string; optionIds: string[] }) => Promise<void>;
  onUnset?: () => Promise<void>;
  onCancel: () => void;
  interesting?: CompanyDbInteresting;
}

const Checkboxes = styled.div`
  > * + * {
    margin-top: 8px;
  }
`;

export const InterestingForm: React.FC<InterestingFormProps> = ({
  header,
  options,
  comment,
  mode,
  companyId,
  onStore,
  onCancel,
  onUnset,
  interesting,
  ...domAttributes
}) => {
  const { i18n } = useI18n();
  const setUnsetInteresting = useUnsetInteresting(companyId);
  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const valueObject: { comment: string; optionIds: string[] } = {
        optionIds: [],
        comment: ''
      };
      for (const { name: key, value, checked } of Object.values(e.target).filter((i) => i.name)) {
        if (key === 'comment') {
          valueObject.comment = value;
        } else {
          const [prefix, optionId] = key.split('_');
          if (prefix === 'option' && checked) {
            valueObject.optionIds.push(optionId);
          }
        }
      }
      await onStore(valueObject);
    },
    [onStore]
  );

  const unset = useCallback(async () => {
    setUnsetInteresting.mutateAsync();
    onCancel();
  }, []);

  return (
    <PopupForm
      {...domAttributes}
      header={header}
      submit={onSubmit}
      onCancel={onCancel}
      interesting={interesting}
      mode={mode}
      onUnset={unset}
    >
      <Checkboxes>
        {options.map(({ id, name, isSelected }) => (
          <CheckboxLabel key={id}>
            <Checkbox size="small" name={`option_${id}`} defaultChecked={isSelected} />
            {name}
          </CheckboxLabel>
        ))}
      </Checkboxes>
      <OverControlLabel style={{ marginTop: 16 }}>
        {i18n('interesting_form.comment_label', 'Comment')}
        <TextArea name="comment" defaultValue={comment} />
      </OverControlLabel>
    </PopupForm>
  );
};

InterestingForm.displayName = 'InterestingForm';
