import { CircularProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { kompas } from './layout';

const Grid = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

export const Spinner: React.FC<{ size?: number }> = ({ size }) => {
  return (
    <Grid>
      <CircularProgress sx={{ color: kompas.colors.red }} size={size || 100} />
    </Grid>
  );
};
