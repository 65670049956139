import ReactFlow, {
  MiniMap,
  Controls,
  ControlButton,
  useZoomPanHelper,
  ReactFlowProvider,
  useUpdateNodeInternals,
  ZoomPanHelperFunctions,
  UpdateNodeInternals
} from 'react-flow-renderer';

import { useCompanyStructure, useUpdateOwnershipPercentage } from '../../api/company';
import { Spinner } from '../spinner';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CompanyGraphNode } from './companyNode';
import { Dialog, DialogContent, Paper } from '@mui/material';
import { Graph, layoutDagreGraph } from './dagreGraph';
import { LabelledEdge } from './labelledEdge';
import { useMediaQuery } from '@mui/material';
import { CloseFullscreen, Download, OpenInFull } from '@mui/icons-material';
import { captureImage } from './captureImage';
import { topbarBreakWidth } from '../layout';
import { useI18n } from '../../i18n/i18n';

const nodeTypes = {
  Company: CompanyGraphNode,
  Person: CompanyGraphNode
};

const edgeTypes = {
  custom: LabelledEdge
};

const rerenderGraph = (zoomHelper: ZoomPanHelperFunctions, updateNode: UpdateNodeInternals, graph?: Graph): void => {
  if (!graph?.length) return;
  for (const e of graph) {
    setTimeout(() => updateNode(e.id), 0);
  }
  setTimeout(() => zoomHelper.fitView());
};

const CompanyStructureInnerGraph: React.FC<{
  graph: Graph;
  companyName: string;
  actions?: { onClick: () => void; icon: JSX.Element; title: string }[];
}> = ({ graph, companyName, actions }) => {
  const { i18n } = useI18n();
  const isNarrow = useMediaQuery('(max-width: 500px)');
  const graphRef = useRef(null);
  const [isDownlading, setIsDownloading] = useState(false);

  const download = useCallback(async () => {
    setIsDownloading(true);
    requestAnimationFrame(() =>
      graphRef.current
        ? captureImage(graphRef.current, companyName).finally(() => setIsDownloading(false))
        : setIsDownloading(false)
    );
  }, [graphRef.current]);

  if (!graph) return <div>Loading graph</div>;

  return (
    <ReactFlow
      style={{ background: '#fff' }}
      ref={graphRef}
      elements={graph}
      onLoad={(r) => r.fitView()}
      snapToGrid={true}
      snapGrid={[15, 15]}
      maxZoom={1.4}
      minZoom={0.6}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
    >
      <MiniMap
        style={{
          display: isDownlading ? 'none' : undefined,
          transform: isNarrow ? 'scale(30%) translate(120%, 120%)' : undefined
        }}
        nodeStrokeColor={'#1a192b'}
        nodeColor={'#fff'}
        nodeBorderRadius={2}
      />
      <Controls style={{ display: isDownlading ? 'none' : undefined }}>
        <ControlButton title={i18n('action.download', 'Download')} onClick={download}>
          <Download />
        </ControlButton>
        {actions?.map(({ title, icon, onClick }) => (
          <ControlButton key={title} title={title} onClick={onClick}>
            {icon}
          </ControlButton>
        ))}
      </Controls>
    </ReactFlow>
  );
};

const CompanyStructureFullScreenModal: React.FC<{
  graph: Graph;
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
}> = ({ graph, isOpen, onClose, companyName }) => {
  const { i18n } = useI18n();
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <DialogContent style={{ width: '100%', height: '100%', padding: 0 }}>
        <CompanyStructureInnerGraph
          graph={graph}
          companyName={companyName}
          actions={[
            {
              title: i18n('action.exit_fullscreen', 'Exit fullscreen'),
              onClick: () => onClose(),
              icon: <CloseFullscreen />
            }
          ]}
        />
      </DialogContent>
    </Dialog>
  );
};

const CompanyStructureGraphWithFullScreen: React.FC<{ cid: string; companyName: string }> = ({ cid, companyName }) => {
  const { i18n } = useI18n();
  const baseHeight = window.innerHeight - 80 - (window.innerWidth < topbarBreakWidth ? 50 : 0);

  const structure = useCompanyStructure(cid);
  const updateOwnershipPercentageMutation = useUpdateOwnershipPercentage(cid);
  const updateOwnershipPercentage = useCallback(
    (owner: string, subsidiary: string, ownershipPercentage: string): void => {
      updateOwnershipPercentageMutation.mutateAsync({ owner, subsidiary, ownershipPercentage });
    },
    [updateOwnershipPercentageMutation.mutateAsync]
  );

  const zoomPanHelper = useZoomPanHelper();
  const updateNode = useUpdateNodeInternals();
  const [height, setHeight] = useState(baseHeight);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [graph, setGraph] = useState<Graph>();

  useEffect(() => {
    if (!structure.data) {
      return;
    }
    const createGraph = (): void => {
      const graph = layoutDagreGraph(updateOwnershipPercentage, structure.data);
      setGraph(graph);
    };
    createGraph();
  }, [structure.data, updateOwnershipPercentage, cid]);

  useEffect(() => {
    // minor hack to avoid some edge-caching inside react-flow when navigating the graph
    rerenderGraph(zoomPanHelper, updateNode, graph);
  }, [graph]);

  if (!structure.isLoading && (!structure.data?.nodes || !structure.data?.edges)) {
    return <div>{i18n('company_structure.no_data', 'no data to show!')}</div>;
  }

  return (
    <>
      {graph && (
        <CompanyStructureFullScreenModal
          graph={graph}
          companyName={companyName}
          onClose={() => {
            setIsFullscreen(false);
            setHeight(height === baseHeight ? baseHeight + 0.1 : baseHeight); // hack to make graph re-size when switching from fullscreen to normal view.
            setTimeout(() => zoomPanHelper.fitView(), 0); // re-fit graph in container after resize.
          }}
          isOpen={isFullscreen}
        />
      )}
      <Paper>
        <div style={{ width: '100%', height }}>
          {structure.isLoading && <Spinner />}
          {graph && (
            <CompanyStructureInnerGraph
              graph={graph}
              companyName={companyName}
              actions={[
                {
                  title: i18n('action.fullscreen', 'Fullscreen'),
                  onClick: () => setIsFullscreen(true),
                  icon: <OpenInFull />
                }
              ]}
            />
          )}
        </div>
      </Paper>
    </>
  );
};

export const CompanyStructureGraph: React.FC<{ cid: string; companyName: string }> = ({ cid, companyName }) => (
  <ReactFlowProvider>
    <CompanyStructureGraphWithFullScreen cid={cid} companyName={companyName} />
  </ReactFlowProvider>
);
