import {
  Add,
  CheckBox,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  Delete,
  Done,
  Edit,
  Event,
  EventBusy,
  ImportContacts,
  Phone,
  PhoneAndroid,
  SvgIconComponent,
  Update
} from '@mui/icons-material';
import {
  Badge,
  ButtonProps,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  Icon,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from '@mui/material';
import React, { FormEvent, HTMLAttributes, useCallback, useState } from 'react';
import {
  AddOrUpdateActivityOptions,
  useActivities,
  useAddActivity,
  useDeleteActivity,
  useMarkActivityDone,
  useTransferToCrm
} from '../api/company';
import { Activity, ActivityType } from '../generated/graphql';
import { InlineLabel, OverControlLabel, TextArea } from './Form/formInput';
import { PopupForm } from './Form/popupForm';
import { PopupMenu } from './PopupMenu';
import { useUserProfile } from '../api/user';
import styled from 'styled-components';
import { TranslationFunction, useI18n } from '../i18n/i18n';

type OnAddNoteHandler = (props: AddOrUpdateActivityOptions) => Promise<void>;

type PhoneCallTypes = ActivityType.Call | ActivityType.MeetingBooked | ActivityType.Rejected;

const getpPhoneCallOptions: (
  i18n: TranslationFunction
) => Record<PhoneCallTypes, { header: string; Icon: SvgIconComponent }> = (i18n) => ({
  [ActivityType.Call]: {
    header: i18n('activity_type.phone_call', 'Phone call'),
    Icon: PhoneAndroid
  },
  [ActivityType.Rejected]: {
    header: i18n('activity_type.meeting_declined', 'Meeting declined'),
    Icon: EventBusy
  },
  [ActivityType.MeetingBooked]: {
    header: i18n('activity_type.meeting_booked', 'Meeting booked'),
    Icon: Event
  }
});

interface AddActivityFormProps extends HTMLAttributes<HTMLDivElement> {
  onAddNote: OnAddNoteHandler;
  onClose: () => void;
  type?: ActivityType;
  header?: string;
  entry?: Activity;
}

const AddActivityLogForm: React.FC<AddActivityFormProps> = ({ onAddNote, onClose, header, entry, type }) => {
  const { i18n } = useI18n();
  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const valueObject: { note: string; followUpDate?: string; updated?: string } = {
        note: '',
        updated: entry?.updated ? entry.updated : undefined
      };
      for (const { name: key, value } of Object.values(e.target).filter((i) => i.name)) {
        if (value && ['note', 'followUpDate'].includes(key)) {
          valueObject[key as 'note' | 'followUpDate'] = value;
        }
      }
      await onAddNote(
        entry
          ? { ...valueObject, activityId: entry.activity_id! }
          : { ...valueObject, type: type || ActivityType.Other }
      );

      onClose();
    },
    [onAddNote, onClose]
  );

  return (
    <PopupForm header={header || (entry ? 'Edit activity' : 'Add activity')} onCancel={onClose} submit={onSubmit}>
      <InlineLabel>
        {i18n('activity_log.follow_up_date', 'Follow up date')}
        <Input style={{ marginLeft: 12 }} name="followUpDate" type="date" defaultValue={entry?.follow_up_date} />
      </InlineLabel>
      <OverControlLabel>
        {i18n('activity_log.note', 'Note')}
        <TextArea name="note" defaultValue={entry?.note || ''} />
      </OverControlLabel>
    </PopupForm>
  );
};

const getActivityHeader = (i18n: TranslationFunction, activity?: Activity): string | undefined => {
  const phoneCallOptions = getpPhoneCallOptions(i18n);
  return activity?.type && activity.type in phoneCallOptions
    ? phoneCallOptions[activity.type as PhoneCallTypes].header
    : undefined;
};

interface AddOrEditActivityLogButton extends ButtonProps {
  onAddNote: OnAddNoteHandler;
  entry?: Activity;
}

const AddOrEditActivityLogButton: React.FC<AddOrEditActivityLogButton> = ({
  onAddNote,
  entry,
  size,
  ...domAttributes
}) => {
  const { i18n } = useI18n();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IconButton {...domAttributes} size={size} onClick={() => setIsOpen(true)}>
        {entry ? <Edit fontSize={size} /> : <Add fontSize={size} />}
      </IconButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogContent style={{ background: '#fff' }}>
          <AddActivityLogForm
            header={getActivityHeader(i18n, entry)}
            type={entry?.type || ActivityType.Other}
            onAddNote={onAddNote}
            onClose={() => setIsOpen(false)}
            entry={entry}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const todayPlusDays = (addedDays: number): string => {
  const today = new Date();
  today.setDate(today.getDate() + addedDays);
  const [isoDate] = today.toISOString().split('T');
  return isoDate;
};

const PhoneCallResult: React.FC<{ onAddNote: OnAddNoteHandler }> = ({ onAddNote }) => {
  const { i18n } = useI18n();
  const phoneCallOptions = getpPhoneCallOptions(i18n);
  const [selectionState, setSelectionState] = useState<PhoneCallTypes>();
  return (
    <>
      <PopupMenu
        Icon={Phone}
        items={Object.entries(phoneCallOptions).map(([key, { header, Icon }]) => ({
          key,
          header,
          Icon,
          action: () => setSelectionState(key as PhoneCallTypes)
        }))}
      />
      <Dialog open={!!selectionState} onClose={() => setSelectionState(undefined)}>
        <DialogContent style={{ background: '#fff' }}>
          <AddActivityLogForm
            header={selectionState && phoneCallOptions[selectionState].header}
            onAddNote={onAddNote}
            onClose={() => setSelectionState(undefined)}
            type={selectionState}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const QuickActionButton: React.FC<{
  title: string;
  icon: JSX.Element;
  setFollowup?: boolean;
  onAddNote: OnAddNoteHandler;
  disabled?: boolean;
}> = ({ title, icon, setFollowup, onAddNote, disabled }) => (
  <IconButton
    onClick={() =>
      onAddNote({
        note: title,
        type: ActivityType.Other,
        followUpDate: setFollowup ? todayPlusDays(7) : undefined
      })
    }
    disabled={disabled}
    title={title}
  >
    {icon}
  </IconButton>
);

interface ActivityLogProps extends HTMLAttributes<HTMLDivElement> {
  cid: string;
  ncino?: boolean;
}

const toDate = (date: string): Date => {
  try {
    return new Date(date);
  } catch (_) {
    return new Date(0);
  }
};

const overdue = (activities: Activity[]): boolean => {
  const now = new Date();
  return activities.some((x) => !!x.follow_up_date && !x.follow_up_done && toDate(x.follow_up_date) <= now);
};

const RenderBadge: React.FC<{ children: JSX.Element; badgeContent?: React.ReactNode }> = ({ children, badgeContent }) =>
  badgeContent || badgeContent === 0 ? (
    <Badge color="info" max={9} badgeContent={badgeContent}>
      {children}
    </Badge>
  ) : (
    children
  );

const ChipContainer = styled.div`
  display: inline-block;
  margin: 0 8px;
  > * + * {
    margin-left: 8px;
  }
`;

const ToggleButtons: React.FC<{
  items: { label: string; onToggle: () => void; isToggled: boolean; badgeContent?: React.ReactNode }[];
}> = ({ items }) => {
  const { i18n } = useI18n();
  const isWidthLimited = useMediaQuery('(max-width: 600px)');
  return isWidthLimited ? (
    <PopupMenu
      buttonLabel={i18n('activity_log.filters', 'Filters')}
      items={items.map(({ label, isToggled, onToggle }) => ({
        key: label,
        header: label,
        Icon: isToggled ? CheckBoxOutlined : CheckBoxOutlineBlankOutlined,
        action: onToggle
      }))}
    />
  ) : (
    <ChipContainer>
      {items.map(({ label, onToggle, isToggled, badgeContent }) => (
        <RenderBadge key={label} badgeContent={badgeContent}>
          <Chip label={label} variant={isToggled ? 'filled' : 'outlined'} onClick={onToggle} />
        </RenderBadge>
      ))}
    </ChipContainer>
  );
};

const Note: React.FC<{ activity: Activity }> = ({ activity }) => {
  const { i18n } = useI18n();
  const parts: string[] = [];
  const header = getActivityHeader(i18n, activity);
  if (header) {
    parts.push(header);
  }
  if (activity.note) {
    parts.push(activity.note);
  }
  return <>{parts.join(' — ')}</>;
};

export const ActivityLog: React.FC<ActivityLogProps> = ({ cid, ncino, ...domAttributes }) => {
  const { i18n } = useI18n();
  const activitiesFetch = useActivities(cid);
  const activities = activitiesFetch.data || [];
  const { data: userProfile } = useUserProfile();
  const addActivityMutation = useAddActivity(cid);
  const deleteActivity = useDeleteActivity(cid);
  const markActivityDone = useMarkActivityDone(cid);
  const transferToCrm = useTransferToCrm(cid);

  const addPhoneCall = useCallback<OnAddNoteHandler>(
    async (props) => {
      try {
        if (!ncino && 'type' in props && props.type === ActivityType.MeetingBooked) {
          await transferToCrm.mutateAsync();
        }
        await addActivityMutation.mutateAsync(props);
      } catch (e) {
        // intentionally left blank
      }
    },
    [ncino, transferToCrm, addActivityMutation]
  );

  const [onlyMine, setOnlyMine] = useState(false);
  const [hideDone, setHideDone] = useState(false);

  return (
    <Card {...domAttributes}>
      <CardHeader
        title={i18n('activity_log.title', 'Activity')}
        titleTypographyProps={{ variant: 'h5', component: 'h3' }}
        avatar={
          <Badge
            color={overdue(activities) ? 'error' : 'success'}
            max={9}
            badgeContent={activities.filter((x) => x.follow_up_date && !x.follow_up_done).length}
          >
            <ImportContacts />
          </Badge>
        }
        action={
          <>
            <ToggleButtons
              items={[
                {
                  label: i18n('activity_log.hide_done_label', 'Hide done'),
                  isToggled: hideDone,
                  onToggle: () => setHideDone(!hideDone)
                },
                {
                  label: i18n('activity_log.my_activities_label', 'My activities'),
                  isToggled: onlyMine,
                  onToggle: () => setOnlyMine(!onlyMine),
                  badgeContent: activities.filter((x) => x.name === userProfile?.name).length
                }
              ]}
            />

            <QuickActionButton
              title={i18n('activity_log.remind_me_title', 'Remind me in 7 days')}
              setFollowup
              icon={<Update />}
              onAddNote={addActivityMutation.mutateAsync}
            />
            <PhoneCallResult onAddNote={addPhoneCall} />
            <AddOrEditActivityLogButton onAddNote={addActivityMutation.mutateAsync} />
          </>
        }
      ></CardHeader>
      {!!activities.length && (
        <TableContainer sx={{ maxHeight: 200 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    height: 18,
                    minWidth: 100,
                    fontWeight: 400,
                    padding: '0 16px'
                  }}
                >
                  {i18n('activity_log.user', 'User')}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    height: 18,
                    minWidth: 100,
                    fontWeight: 400,
                    padding: '0 16px'
                  }}
                >
                  {i18n('activity_log.note', 'Note')}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    height: 18,
                    maxWidth: 100,
                    fontWeight: 400,
                    padding: '0 16px'
                  }}
                >
                  {i18n('activity_log.folloe_up', 'Follow up')}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    height: 18,
                    minWidth: 60,
                    fontWeight: 400,
                    padding: '0 16px'
                  }}
                >
                  {i18n('activity_log.done', 'Done')}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    height: 18,
                    minWidth: 100,
                    fontWeight: 400,
                    padding: '0 16px'
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {activities
                .filter(
                  (x) =>
                    (!hideDone || (x.follow_up_date && !x.follow_up_done)) &&
                    (!onlyMine || x.name === userProfile?.name)
                )
                .map((activity, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        height: 18,
                        minWidth: 100,
                        padding: '8px 16px'
                      }}
                    >
                      {activity.name}
                    </TableCell>
                    <TableCell
                      style={{
                        height: 18,
                        maxWidth: 0,
                        width: '100%',
                        padding: '8px 16px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      <Note activity={activity} />
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        height: 18,
                        minWidth: 90,
                        padding: '8px 16px'
                      }}
                    >
                      {activity.follow_up_date}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        height: 18,
                        maxWidth: 60,
                        padding: '8px 16px'
                      }}
                    >
                      {(activity.follow_up_done || !activity.follow_up_date) && (
                        <Icon fontSize="small">
                          <Done color="success" fontSize="small" />
                        </Icon>
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        height: 18,
                        minWidth: 100,
                        padding: '8px 16px'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          justifyItems: 'center'
                        }}
                      >
                        <AddOrEditActivityLogButton
                          size="small"
                          title="Edit"
                          entry={activity}
                          disabled={!activity.activity_id}
                          onAddNote={addActivityMutation.mutateAsync}
                        />
                        <IconButton
                          size="small"
                          title="Delete"
                          disabled={!activity.activity_id || activity.name !== userProfile?.name}
                          onClick={() => deleteActivity.mutateAsync({ activityId: activity.activity_id! })}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                        <IconButton
                          disabled={!activity.activity_id || !activity.follow_up_date || !!activity.follow_up_done}
                          size="small"
                          title="Mark as done"
                          onClick={() => markActivityDone.mutateAsync({ activityId: activity.activity_id! })}
                        >
                          <CheckBox fontSize="small" />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  );
};
