import styled, { keyframes } from 'styled-components';

export type CssLength = number | string;

function cssLengthToCssValue(l?: CssLength, defaultValue?: string): string | undefined {
  if (l === undefined) {
    return defaultValue;
  }

  if (typeof l === 'number' && !isNaN(l)) {
    return `${l}px`;
  }
  return l as string;
}

const SkeletonShimmerAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

export const SkeletonElement = styled.span<{ height?: CssLength; width: CssLength }>`
  display: inline-block;
  height: ${(p) => cssLengthToCssValue(p.height, '1em')};
  width: ${(p) => cssLengthToCssValue(p.width)};
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(90deg, #f2f2f2 0, #ededed 20%, #f2f2f2 80%);

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${SkeletonShimmerAnimation} 1s infinite;
    content: '';
  }
`;
