import { HTMLAttributes } from 'react';

const NCinoLogoDefs: React.FC = () => (
  <defs>
    <linearGradient id="linear-gradient" x1="157.1" y1="146.98" x2="154.34" y2="68.05" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#9f1e2e" />
      <stop offset="0.49" stopColor="#af1f2d" />
      <stop offset="0.96" stopColor="#ba202e" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-2"
      x1="108.43"
      y1="166.28"
      x2="108.43"
      y2="16.85"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#b5812d" />
      <stop offset="0.27" stopColor="#d09335" />
      <stop offset="0.5" stopColor="#e3a03a" />
      <stop offset="0.72" stopColor="#fbaf41" />
      <stop offset="0.94" stopColor="#fbaf41" />
    </linearGradient>
    <linearGradient id="linear-gradient-3" x1="62.53" y1="159.63" x2="62.53" y2="33.12" gradientUnits="userSpaceOnUse">
      <stop offset="0.12" stopColor="#62933f" />
      <stop offset="0.34" stopColor="#76a942" />
      <stop offset="0.53" stopColor="#86bf40" />
      <stop offset="0.8" stopColor="#8cc640" />
      <stop offset="0.91" stopColor="#8cc640" />
    </linearGradient>
    <linearGradient id="linear-gradient-4" x1="16.63" y1="152.97" x2="16.63" y2="14.89" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#0482b3" />
      <stop offset="0.29" stopColor="#0093c8" />
      <stop offset="0.5" stopColor="#0fa1dc" />
      <stop offset="0.94" stopColor="#c2e3f7" />
    </linearGradient>
  </defs>
);
export const NCinoLogo: React.FC<HTMLAttributes<SVGElement>> = ({ ...domAttributes }) => (
  <svg {...domAttributes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173 135.76">
    <NCinoLogoDefs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect x="137.7" width="33.26" height="135.26" style={{ fill: 'url(#linear-gradient)' }} />
        <rect x="91.8" y="55.58" width="33.26" height="79.68" style={{ fill: 'url(#linear-gradient-2)' }} />
        <rect x="45.9" y="35.49" width="33.26" height="99.77" style={{ fill: 'url(#linear-gradient-3)' }} />
        <rect y="69.64" width="33.26" height="65.62" style={{ fill: 'url(#linear-gradient-4)' }} />
      </g>
    </g>
  </svg>
);

export const NCinoTextLogo: React.FC<HTMLAttributes<SVGElement>> = ({ ...domAttributes }) => (
  <svg {...domAttributes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 537.98 135.76">
    <NCinoLogoDefs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect x="137.7" width="33.26" height="135.26" style={{ fill: 'url(#linear-gradient)' }} />
        <rect x="91.8" y="55.58" width="33.26" height="79.68" style={{ fill: 'url(#linear-gradient-2)' }} />
        <rect x="45.9" y="35.49" width="33.26" height="99.77" style={{ fill: 'url(#linear-gradient-3)' }} />
        <rect y="69.64" width="33.26" height="65.62" style={{ fill: 'url(#linear-gradient-4)' }} />
        <path
          d="M465.7,127.31a28.22,28.22,0,0,1-8.8-13.89,62.54,62.54,0,0,1-2.64-18.18,56.36,56.36,0,0,1,2.2-15.46,31.66,31.66,0,0,1,6.89-12.89A30.66,30.66,0,0,1,483,56.72a46.25,46.25,0,0,1,21.7,1.86,29.62,29.62,0,0,1,16.42,14.89q5,9.45,5,22a53.45,53.45,0,0,1-2.05,15.75,40,40,0,0,1-5.28,11.17,29.35,29.35,0,0,1-17.89,11.74,40.87,40.87,0,0,1-19.5.43Q471.57,132.46,465.7,127.31Zm14.08-50.4q-6.16,6.59-5.57,19.62t5,18.18q1.76,2.29,6.16,3.86a14.32,14.32,0,0,0,8.94.29q4.54-1.29,8.06-6.44t3.82-16.61q0-10.31-3.38-15.46T495,73.76a14.58,14.58,0,0,0-8.8-.14A15.39,15.39,0,0,0,479.78,76.91ZM263,62.57a44.15,44.15,0,0,0-23.75-6.75,45.49,45.49,0,0,0-23,7.33q-10.41,6.75-10.41,26.68v45.74h20.53V85.73q0-7,4.1-10.26a14.78,14.78,0,0,1,9.39-3.23,15.39,15.39,0,0,1,9.53,3.23q4.25,3.23,4.25,10.26v49.85h20.23V89.84Q273.89,69.32,263,62.57Zm175.66,0A44.15,44.15,0,0,0,415,55.82a45.51,45.51,0,0,0-23,7.33q-10.41,6.75-10.41,26.68v45.74H402V85.73q0-7,4.11-10.26a14.79,14.79,0,0,1,9.39-3.23,15.38,15.38,0,0,1,9.53,3.23q4.25,3.23,4.25,10.26v49.85h20.23V89.84Q449.55,69.32,438.7,62.57Zm-112.35,49.5q-3.52,5.16-8.06,6.44a14.32,14.32,0,0,1-8.94-.29q-4.4-1.57-6.16-3.87-4.4-5.15-5-18.18t5.57-19.61a15.36,15.36,0,0,1,6.45-3.29,14.56,14.56,0,0,1,8.79.14q4.4,1.44,7.77,6.59a16.59,16.59,0,0,1,1.66,3.44h20.42a41.39,41.39,0,0,0-3.75-10.31A29.63,29.63,0,0,0,328.7,58.24,46.26,46.26,0,0,0,307,56.38a30.66,30.66,0,0,0-19.65,10.16,31.65,31.65,0,0,0-6.89,12.89,56.37,56.37,0,0,0-2.2,15.46,62.52,62.52,0,0,0,2.64,18.18A28.24,28.24,0,0,0,289.7,127q5.86,5.15,15.69,7.3a40.84,40.84,0,0,0,19.5-.43,29.35,29.35,0,0,0,17.89-11.74,40,40,0,0,0,5.28-11.17q.5-1.62.88-3.4H328.47A17.4,17.4,0,0,1,326.36,112.07Zm37.76-56.38H353.56v80.07h20.52V65.66a10,10,0,0,0-10-10Zm1.05-6.46a11.17,11.17,0,0,1,7.76,2.93,10.94,10.94,0,0,1,1.15,1.26v-18H363.53a10,10,0,0,0-10,10v3.87Z"
          style={{ fill: '#231f20' }}
        />
        <path
          d="M538,128.36a7.21,7.21,0,1,1-7.14-7A7.07,7.07,0,0,1,538,128.36Zm-12.62,0a5.46,5.46,0,0,0,5.48,5.61,5.37,5.37,0,0,0,5.35-5.56,5.42,5.42,0,1,0-10.82,0Zm4.32,3.64h-1.63v-7a19.05,19.05,0,0,1,2.7-.21,3.73,3.73,0,0,1,2.4.56,1.77,1.77,0,0,1,.73,1.5,1.81,1.81,0,0,1-1.45,1.67v.09c.68.21,1,.77,1.24,1.71a6.23,6.23,0,0,0,.51,1.71h-1.71a5.54,5.54,0,0,1-.56-1.71c-.13-.73-.56-1.07-1.46-1.07h-.77Zm0-3.94h.77c.9,0,1.63-.3,1.63-1s-.47-1.07-1.5-1.07a3.89,3.89,0,0,0-.9.09Z"
          style={{ fill: '#231f20' }}
        />
      </g>
    </g>
  </svg>
);
