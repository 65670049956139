import { useState, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAddSimulation, useFinancials } from '../../api/company';
import { FinanceDataGrid2 } from '../../components/financeDataGrid2';
import { H2 } from '../../components/layout';
import { ReportType } from '../../generated/graphql';
import { useI18n } from '../../i18n/i18n';
import { CompanyContext } from '../company';

export const FinancialsPage: React.FC = () => {
  const { i18n } = useI18n();
  const { companyId } = useOutletContext<CompanyContext>();
  const holding = false;
  const [simulate, setSimulate] = useState(true);
  const report = useFinancials(companyId, holding, ReportType.Financials, simulate);
  const addSimulation = useAddSimulation(companyId, holding).mutateAsync;

  const toggleSimulation = useCallback(
    (on: boolean): void => {
      setSimulate(on);
      if (on) {
        addSimulation({});
      }
    },
    [setSimulate, addSimulation]
  );

  return (
    <>
      <H2>{i18n('company_overview.financials', 'Financials')}</H2>
      <FinanceDataGrid2
        cid={companyId}
        holding={holding}
        report={report.data}
        isLoading={report.isLoading}
        toggleSimulation={() => toggleSimulation(!simulate)}
      />
    </>
  );
};
