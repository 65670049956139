import { blueGrey } from '@mui/material/colors';
import styled from 'styled-components';

export const FormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > button + button {
    margin-left: 8px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 8px;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InlineLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const OverControlLabel = styled.label`
  display: flex;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  min-height: 100px;
  border: 1px solid ${blueGrey[100]};
  font-family: inherit;
  margin-top: 4px;
  padding: 4px;
`;
