import IntlMessageFormat, { PrimitiveType } from 'intl-messageformat';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUserProfile } from '../api/user';
import { cachedLocale } from '../api/userProfile';
import { loadLanguage } from './loadLanguage';

export type TranslationFunction = (
  translationKey: string,
  message: string,
  replacements?: Record<string, PrimitiveType>
) => string;

const formatterCache: Map<string, IntlMessageFormat> = new Map<string, IntlMessageFormat>();

const i18n = (
  messages: Messages,
  translationKey: string,
  message: string,
  replacements?: Record<string, PrimitiveType>
): string => {
  const translatedMessage = messages[translationKey] || message;
  if (!replacements) {
    return translatedMessage;
  }
  let formatter = formatterCache.get(translationKey);
  if (!formatter) {
    formatter = new IntlMessageFormat(translatedMessage, 'en-US');
  }
  return formatter.format(replacements)?.toString() || '';
};

export type Messages = Record<string, string>;

export type Locale = 'da-DK' | 'en-US';

interface TranslationContextProps {
  i18n: TranslationFunction;
}

const defaultContext: TranslationContextProps = {
  i18n: (_: string, message: string): string => message
};

const TranslationContext = createContext<TranslationContextProps>(defaultContext);

export const TranslationProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const profile = useUserProfile();
  const [translationContext, setTranslationContext] = useState<TranslationContextProps>(defaultContext);
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      formatterCache.clear();
      const messages = await loadLanguage(profile.data?.locale || cachedLocale());
      setTranslationContext({
        i18n: (translationKey: string, message: string, replacements?: Record<string, PrimitiveType>) =>
          i18n(messages, translationKey, message, replacements)
      });
    };
    initialize();
  }, [profile.data?.locale]);
  return <TranslationContext.Provider value={translationContext}>{children}</TranslationContext.Provider>;
};

export const useI18n = (): { i18n: TranslationFunction } => {
  return useContext(TranslationContext);
};
