import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { H1, H2 } from '../components/layout';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessIcon from '@mui/icons-material/Business';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useRelations } from '../api/company';
import { CompanyDbPerson } from '../generated/graphql';

export const Connections: React.FC = () => {
  const { psid } = useParams<{ psid: string }>();
  const navigate = useNavigate();
  const personId = psid!;
  const relations = useRelations(personId);
  const [connection] = relations.data || [];
  const { state: navigationState } = useLocation() as { state: { person: CompanyDbPerson } };

  return (
    <div>
      <H1>{connection?.name || navigationState?.person?.name || `Person ${personId}`}</H1>
      <H2>Overview</H2>
      <Card>
        <List sx={{ width: '100%' }}>
          {relations.data?.map((connection) => (
            <ListItem key={connection.role + '_' + connection.company?.cid}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText
                primary={connection.company?.name}
                secondary={
                  <>
                    {connection.role}
                    <br />
                    {connection.period_begin}
                    <br />
                    Rating: {connection.company?.rating || '-'}, Credit Exception:
                    {connection.company?.credit_flags_sum || '-'}, Credit Max :{' '}
                    {connection.company?.max_loan_amount || '-'} DKK
                  </>
                }
              />
              <Button
                onClick={() =>
                  navigate(`/companies/${connection.company?.cid}/`, {
                    state: { name: connection.company?.name, cid: connection.company?.cid }
                  })
                }
                color="secondary"
                endIcon={<KeyboardArrowRightIcon />}
              >
                view
              </Button>
            </ListItem>
          ))}
        </List>
      </Card>
    </div>
  );
};
