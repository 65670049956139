/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Activity = {
  __typename?: 'Activity';
  activity_id?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  follow_up_date?: Maybe<Scalars['String']>;
  follow_up_done?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  type?: Maybe<ActivityType>;
  updated?: Maybe<Scalars['String']>;
};

export enum ActivityType {
  Call = 'CALL',
  InboundContact = 'INBOUND_CONTACT',
  InboundLead = 'INBOUND_LEAD',
  MeetingBooked = 'MEETING_BOOKED',
  Other = 'OTHER',
  Rejected = 'REJECTED'
}

export type CompanyDbCompany = ICompany & {
  __typename?: 'CompanyDBCompany';
  address_co_name?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_floor?: Maybe<Scalars['String']>;
  address_postal_code?: Maybe<Scalars['String']>;
  address_region?: Maybe<Scalars['String']>;
  address_street_name?: Maybe<Scalars['String']>;
  address_street_number?: Maybe<Scalars['String']>;
  address_town?: Maybe<Scalars['String']>;
  advertising_protection?: Maybe<Scalars['Int']>;
  authority_to_sign?: Maybe<Scalars['String']>;
  board_of_directors: Array<Maybe<CompanyDbPerson>>;
  cid: Scalars['String'];
  company_events: Array<Maybe<CompanyDbCompanyEvents>>;
  company_registry_id: Scalars['String'];
  contact_email?: Maybe<Scalars['String']>;
  contact_phone_number?: Maybe<Scalars['String']>;
  contact_phone_number_country_code?: Maybe<Scalars['String']>;
  credit_analysis: Array<Maybe<CompanyDbCreditAnalysis>>;
  credit_flag_currency?: Maybe<Scalars['Boolean']>;
  credit_flag_geography?: Maybe<Scalars['Boolean']>;
  credit_flag_industry?: Maybe<Scalars['Boolean']>;
  credit_flag_legal_entity?: Maybe<Scalars['Boolean']>;
  credit_flag_leverage?: Maybe<Scalars['Boolean']>;
  credit_flag_liquidity?: Maybe<Scalars['Boolean']>;
  credit_flag_novelty?: Maybe<Scalars['Boolean']>;
  credit_flag_profitability?: Maybe<Scalars['Boolean']>;
  credit_flag_reports?: Maybe<Scalars['Boolean']>;
  credit_flag_size?: Maybe<Scalars['Boolean']>;
  credit_flag_solvency?: Maybe<Scalars['Boolean']>;
  credit_flag_status?: Maybe<Scalars['Boolean']>;
  credit_flag_tardiness?: Maybe<Scalars['Boolean']>;
  credit_flags_sum?: Maybe<Scalars['Int']>;
  directors: Array<Maybe<CompanyDbPerson>>;
  eid: Scalars['String'];
  employees_number?: Maybe<Scalars['Int']>;
  employees_range?: Maybe<Scalars['String']>;
  equity_ratio_score?: Maybe<Scalars['Int']>;
  favorite?: Maybe<Scalars['Boolean']>;
  financial_year_begin?: Maybe<Scalars['String']>;
  financial_year_end?: Maybe<Scalars['String']>;
  founded?: Maybe<Scalars['Int']>;
  geographical_unit: CompanyDbGeographicalUnit;
  industry_class_code?: Maybe<Scalars['String']>;
  industry_class_name?: Maybe<Scalars['String']>;
  industry_division_code?: Maybe<Scalars['String']>;
  industry_division_name?: Maybe<Scalars['String']>;
  industry_domestic_code?: Maybe<Scalars['String']>;
  industry_domestic_name?: Maybe<Scalars['String']>;
  industry_group_code?: Maybe<Scalars['String']>;
  industry_group_name?: Maybe<Scalars['String']>;
  industry_hhi_code?: Maybe<Scalars['String']>;
  industry_hhi_name?: Maybe<Scalars['String']>;
  industry_section_code?: Maybe<Scalars['String']>;
  industry_section_name?: Maybe<Scalars['String']>;
  interesting?: Maybe<CompanyDbInteresting>;
  key_figures?: Maybe<KeyFigures>;
  legal_entity_type?: Maybe<Scalars['Int']>;
  legal_entity_type_domestic?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Int']>;
  max_loan_amount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  ncino?: Maybe<Scalars['String']>;
  net_debt_to_cffo_score?: Maybe<Scalars['Int']>;
  net_debt_to_ebitda_score?: Maybe<Scalars['Int']>;
  probability?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Int']>;
  return_on_equity_score?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyDbCompanyCompany_EventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyDbCompanyCredit_AnalysisArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyDbCompanyEvents = {
  __typename?: 'CompanyDBCompanyEvents';
  event_date: Scalars['String'];
  event_text: Scalars['String'];
};

export type CompanyDbCreditAnalysis = {
  __typename?: 'CompanyDBCreditAnalysis';
  analysis_date: Scalars['String'];
  credit_flag_currency: Scalars['Boolean'];
  credit_flag_geography: Scalars['Boolean'];
  credit_flag_industry: Scalars['Boolean'];
  credit_flag_legal_entity: Scalars['Boolean'];
  credit_flag_leverage: Scalars['Boolean'];
  credit_flag_liquidity: Scalars['Boolean'];
  credit_flag_novelty: Scalars['Boolean'];
  credit_flag_profitability: Scalars['Boolean'];
  credit_flag_reports: Scalars['Boolean'];
  credit_flag_size: Scalars['Boolean'];
  credit_flag_solvency: Scalars['Boolean'];
  credit_flag_status: Scalars['Boolean'];
  credit_flag_tardiness: Scalars['Boolean'];
  credit_flags_sum: Scalars['Int'];
  max_loan_amount: Scalars['Int'];
  rating: Scalars['Int'];
};

export type CompanyDbGeographicalUnit = {
  __typename?: 'CompanyDBGeographicalUnit';
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type CompanyDbInteresting = {
  __typename?: 'CompanyDBInteresting';
  comment?: Maybe<Scalars['String']>;
  interesting?: Maybe<Scalars['Boolean']>;
  interesting_option_ids?: Maybe<Scalars['String']>;
};

export type CompanyDbInterestingOption = {
  __typename?: 'CompanyDBInterestingOption';
  description?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['String']>;
};

export type CompanyDbPerson = {
  __typename?: 'CompanyDBPerson';
  connections?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  period_begin?: Maybe<Scalars['String']>;
  period_end?: Maybe<Scalars['String']>;
  psid?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type CompanyDbQuery = {
  __typename?: 'CompanyDBQuery';
  getActivities: Array<Maybe<Activity>>;
  getCompany: CompanyDbCompany;
  getCompanyReport: CompanyReport;
  getCompanyStructure: CompanyStructure;
  getDeltas: Array<Maybe<Delta>>;
  getFinancials: Financials;
  getFollowUp: Array<Maybe<FollowUp>>;
  getInterestingOptions: Array<Maybe<CompanyDbInterestingOption>>;
  getProfile?: Maybe<Profile>;
  getRelations: Array<Maybe<PersonalRelation>>;
  listCompanies: Array<Maybe<CompanyDbCompany>>;
};

export type CompanyDbQueryGetActivitiesArgs = {
  cid: Scalars['String'];
};

export type CompanyDbQueryGetCompanyArgs = {
  cid: Scalars['String'];
};

export type CompanyDbQueryGetCompanyReportArgs = {
  cid: Scalars['String'];
  report: ReportType;
};

export type CompanyDbQueryGetCompanyStructureArgs = {
  cid: Scalars['String'];
};

export type CompanyDbQueryGetDeltasArgs = {
  cid: Scalars['String'];
  holding?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyDbQueryGetFinancialsArgs = {
  cid: Scalars['String'];
  holding?: InputMaybe<Scalars['Boolean']>;
  report: ReportType;
  simulate?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyDbQueryGetInterestingOptionsArgs = {
  interesting?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyDbQueryGetRelationsArgs = {
  psid: Scalars['String'];
};

export type CompanyDbQueryListCompaniesArgs = {
  filter?: InputMaybe<Filter>;
  limit?: Scalars['Int'];
  nameSearch?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type CompanyDbUpdate = {
  __typename?: 'CompanyDBUpdate';
  addActivity: Array<Maybe<Activity>>;
  addSimulation?: Maybe<Scalars['String']>;
  deleteActivity?: Maybe<Scalars['String']>;
  deleteFinancialPeriod?: Maybe<Scalars['String']>;
  deleteSimulation?: Maybe<Scalars['String']>;
  markActivityDone?: Maybe<Scalars['String']>;
  saveFinancialPeriod?: Maybe<Scalars['String']>;
  setFavorite?: Maybe<Scalars['String']>;
  setInteresting?: Maybe<Scalars['String']>;
  unSetInteresting?: Maybe<Scalars['String']>;
  updateActivity?: Maybe<Scalars['String']>;
  updateOwnershipPercentage?: Maybe<Scalars['String']>;
  updateProfile?: Maybe<Scalars['String']>;
  updateSimulation?: Maybe<Scalars['String']>;
  uploadCompany?: Maybe<Scalars['String']>;
};

export type CompanyDbUpdateAddActivityArgs = {
  cid: Scalars['String'];
  follow_up_date: Scalars['String'];
  note: Scalars['String'];
  ref: Scalars['String'];
  type: ActivityType;
};

export type CompanyDbUpdateAddSimulationArgs = {
  cid: Scalars['String'];
  holding?: Scalars['Boolean'];
};

export type CompanyDbUpdateDeleteActivityArgs = {
  activity_id: Scalars['String'];
  cid: Scalars['String'];
};

export type CompanyDbUpdateDeleteFinancialPeriodArgs = {
  cid: Scalars['String'];
  period: Scalars['String'];
};

export type CompanyDbUpdateDeleteSimulationArgs = {
  cid: Scalars['String'];
  holding: Scalars['Boolean'];
  id: Scalars['String'];
};

export type CompanyDbUpdateMarkActivityDoneArgs = {
  activity_id: Scalars['String'];
  cid: Scalars['String'];
};

export type CompanyDbUpdateSaveFinancialPeriodArgs = {
  cid: Scalars['String'];
  fields: Array<InputMaybe<FieldDataInput>>;
  period: Scalars['String'];
};

export type CompanyDbUpdateSetFavoriteArgs = {
  cid: Scalars['String'];
  favorite: Scalars['Boolean'];
};

export type CompanyDbUpdateSetInterestingArgs = {
  cid: Scalars['String'];
  comment: Scalars['String'];
  interesting: Scalars['Boolean'];
  interesting_option_ids: Scalars['String'];
};

export type CompanyDbUpdateUnSetInterestingArgs = {
  cid: Scalars['String'];
};

export type CompanyDbUpdateUpdateActivityArgs = {
  activity_id: Scalars['String'];
  cid: Scalars['String'];
  follow_up_date?: InputMaybe<Scalars['String']>;
  note: Scalars['String'];
};

export type CompanyDbUpdateUpdateOwnershipPercentageArgs = {
  owner: Scalars['String'];
  ownershipPercentage?: InputMaybe<Scalars['String']>;
  subsidiary: Scalars['String'];
};

export type CompanyDbUpdateUpdateProfileArgs = {
  email?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type CompanyDbUpdateUpdateSimulationArgs = {
  cid: Scalars['String'];
  field: Scalars['String'];
  holding?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type CompanyDbUpdateUploadCompanyArgs = {
  cid: Scalars['String'];
};

export type CompanyReport = {
  __typename?: 'CompanyReport';
  actions: Array<Maybe<Scalars['String']>>;
  periods: Array<Maybe<Scalars['String']>>;
  rows: Array<Maybe<ReportRow>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CompanyStructure = {
  __typename?: 'CompanyStructure';
  edges?: Maybe<Array<Maybe<CompanyStructureEdge>>>;
  nodes?: Maybe<Array<Maybe<CompanyStructureNode>>>;
};

export type CompanyStructureEdge = {
  __typename?: 'CompanyStructureEdge';
  owner: Scalars['String'];
  ownerCountry: Scalars['String'];
  ownerName: Scalars['String'];
  ownershipPercentage: Scalars['String'];
  ownershipUpdatedPercentage: Scalars['String'];
  startDate: Scalars['String'];
  subsidiary: Scalars['String'];
  type: CompanyStructureNodeType;
};

export type CompanyStructureNode = {
  __typename?: 'CompanyStructureNode';
  company?: Maybe<CompanyDbCompany>;
  internalId: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<CompanyStructureNodeType>;
};

export enum CompanyStructureNodeType {
  Company = 'Company',
  Person = 'Person'
}

export type Delta = {
  __typename?: 'Delta';
  field: Scalars['String'];
  from_value?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  to_value: Scalars['Int'];
};

export type FieldData = {
  __typename?: 'FieldData';
  field: Scalars['String'];
  value: Scalars['String'];
};

export type FieldDataInput = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export enum Filter {
  Favorites = 'FAVORITES',
  History = 'HISTORY',
  Leads = 'LEADS',
  Portfolio = 'PORTFOLIO'
}

export type FinanceRow = {
  __typename?: 'FinanceRow';
  editable?: Maybe<Scalars['Boolean']>;
  field: Scalars['String'];
  headline?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  marks: Array<Maybe<Scalars['Int']>>;
  type?: Maybe<Scalars['String']>;
  values: Array<Maybe<Scalars['Float']>>;
};

export type Financials = {
  __typename?: 'Financials';
  headers: Array<Maybe<Scalars['String']>>;
  ids: Array<Maybe<Scalars['String']>>;
  rows: Array<Maybe<FinanceRow>>;
  simulation: Array<Maybe<Scalars['Boolean']>>;
};

export type FollowUp = {
  __typename?: 'FollowUp';
  activity_id?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyDbCompany>;
  created?: Maybe<Scalars['String']>;
  follow_up_date?: Maybe<Scalars['String']>;
  follow_up_done?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
};

export type ICompany = {
  cid: Scalars['String'];
};

export type KeyFigures = {
  __typename?: 'KeyFigures';
  adjusted_equity?: Maybe<Scalars['Float']>;
  ebitda?: Maybe<Scalars['Float']>;
  equity_ratio?: Maybe<Scalars['Float']>;
  net_debt?: Maybe<Scalars['Float']>;
  net_debt_to_cffo?: Maybe<Scalars['Float']>;
  net_debt_to_ebitda?: Maybe<Scalars['Float']>;
  return_on_equity?: Maybe<Scalars['Float']>;
};

export type PeriodData = {
  __typename?: 'PeriodData';
  fields: Array<Maybe<FieldData>>;
  period: Scalars['String'];
};

export type PersonalRelation = {
  __typename?: 'PersonalRelation';
  company?: Maybe<CompanyDbCompany>;
  name?: Maybe<Scalars['String']>;
  period_begin?: Maybe<Scalars['String']>;
  period_end?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type ReportRow = {
  __typename?: 'ReportRow';
  changes?: Maybe<Array<Maybe<Scalars['String']>>>;
  columns: Array<Maybe<Scalars['String']>>;
  editable?: Maybe<Scalars['Boolean']>;
  field: Scalars['String'];
  headline?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['Boolean']>;
  values: Array<Maybe<Scalars['String']>>;
};

export enum ReportType {
  CreditMemo = 'CREDIT_MEMO',
  Financials = 'FINANCIALS',
  Rating = 'RATING'
}
