import { useEffect, useState } from 'react';
import Ht from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Paper, colors, PaperProps, useMediaQuery } from '@mui/material';
import { Options, SeriesOptionsType } from 'highcharts';

import { Financials } from '../generated/graphql';
import { TranslationFunction, useI18n } from '../i18n/i18n';

const fieldDefinitions = {
  grossProfit: { field: 'gross_profit', color: colors.pink[400] },
  ebit: { field: 'ebit', color: colors.purple[400] },
  netIncome: { field: 'net_income', color: colors.deepPurple[400] },
  netDebtToEbitda: { field: 'net_debt_to_ebitda', color: colors.teal[400] },
  netDebtToCffo: { field: 'net_debt_to_cffo', color: colors.green[400] },
  returnOnEquity: { field: 'return_on_equity', color: colors.amber[400] },
  equityRatio: { field: 'equity_ratio', color: colors.orange[400] },
  rating: {
    field: 'rating',
    color: colors.blue[400]
  }
};

const millionValues = [fieldDefinitions.grossProfit, fieldDefinitions.ebit, fieldDefinitions.netIncome];
const pctValues = [fieldDefinitions.netDebtToEbitda, fieldDefinitions.netDebtToCffo];
const equityValues = [fieldDefinitions.returnOnEquity, fieldDefinitions.equityRatio];

const defaultOptions = (i18n: TranslationFunction, isNarrow: boolean): Options => ({
  title: { text: '' },
  tooltip: {
    shared: true
  },
  yAxis: [
    {
      tickInterval: 1,
      title: { text: i18n('caption.rating', 'Rating') },
      visible: !isNarrow
    },
    {
      title: {
        text: i18n('report_graph.mdkk', 'mDKK'),
        style: {
          fontSize: isNarrow ? '0.8em' : undefined
        }
      },
      labels: {
        rotation: isNarrow ? 270 : 0,
        style: {
          fontSize: isNarrow ? '0.8em' : '1em'
        }
      }
    },
    {
      title: {
        text: i18n('report_graph.debt', 'Debt'),
        style: {
          fontSize: isNarrow ? '0.8em' : undefined
        }
      },
      labels: {
        rotation: isNarrow ? 90 : 0,
        style: {
          fontSize: isNarrow ? '0.8em' : '1em'
        }
      },
      opposite: true
    },
    {
      title: { text: i18n('report_graph.equity', 'Equity') },
      opposite: true,
      visible: !isNarrow
    }
  ],
  xAxis: { categories: [i18n('report_graph.wating-text', 'wating for data')] },
  series: [
    {
      name: '',
      marker: {
        radius: 0
      }
    } as SeriesOptionsType
  ],
  legend: {
    itemStyle: {
      fontWeight: '200'
    }
  },
  credits: {
    enabled: false
  },
  chart: {
    height: 400
  }
});

function getOptions(i18n: TranslationFunction, isNarrow: boolean, report?: Financials): Options {
  const ratings = report?.rows.find((r) => r?.field === 'rating')?.values || [];
  const valueSeries =
    (millionValues
      .map(({ field, color }) => {
        const data = report?.rows.find((r) => r?.field === field);
        if (!data) return;
        return {
          color,
          data: data.values.map((x) => Number(x) / 1000000),
          name: data.label,
          type: 'column',
          yAxis: 1,
          tooltip: {
            valueSuffix: ' ' + i18n('report_graph.mdkk', 'mDKK')
          }
        };
      })
      .filter((x) => !!x)! as SeriesOptionsType[]) || [];

  const pctSeries =
    (pctValues
      .map(({ field, color }) => {
        const data = report?.rows.find((r) => r?.field === field);
        if (!data) return;
        return {
          color,
          data: data.values.map(Number),
          name: data.label,
          marker: {
            radius: 0
          },
          yAxis: 2
        };
      })
      .filter((x) => !!x) as SeriesOptionsType[]) || [];

  const equitySeries =
    (equityValues
      .map(({ field, color }) => {
        const data = report?.rows.find((r) => r?.field === field);
        if (!data) return;
        return {
          color,
          data: data.values,
          name: data.label,
          type: 'line',
          marker: {
            radius: 0
          },
          yAxis: 3
        };
      })
      .filter((x) => !!x) as SeriesOptionsType[]) || [];

  return {
    ...defaultOptions(i18n, isNarrow),
    xAxis: { categories: [...(report?.headers as string[])] },
    series: [
      ...valueSeries,
      ...pctSeries,
      ...equitySeries,
      {
        name: i18n('caption.rating', 'Rating'),
        color: fieldDefinitions.rating.color,
        type: 'spline',
        dashStyle: 'Dash',
        data: ratings.map(Number),
        marker: {
          radius: 0
        },
        yAxis: 0
      }
    ]
  };
}

interface CompanyReportGraphProps extends PaperProps {
  report?: Financials;
}

export const CompanyReportGraph: React.FC<CompanyReportGraphProps> = ({ report, ...domAttributes }) => {
  const { i18n } = useI18n();
  const isNarrow = useMediaQuery('(max-width: 600px)');
  const { style, ...attributes } = domAttributes;
  const [options, setOptions] = useState<Options>(defaultOptions(i18n, isNarrow));

  useEffect(() => {
    if (report) {
      setOptions(getOptions(i18n, isNarrow, report));
    }
  }, [report, isNarrow]);

  return (
    <Paper {...attributes} style={{ ...style, paddingTop: 20, overflow: 'hidden' }}>
      <HighchartsReact highcharts={Ht} options={options} />
    </Paper>
  );
};
