import { useQuery, UseQueryResult, useMutation, UseMutationResult, useQueryClient, QueryClient } from 'react-query';
import {
  CompanyDbCompany,
  CompanyReport,
  CompanyStructure,
  FieldDataInput,
  Filter,
  PersonalRelation,
  FollowUp,
  ReportType,
  Activity,
  ActivityType,
  Financials
} from '../generated/graphql';
import { regions, useApi } from './api';
import { AuthError } from './auth';
import { followUpsCacheKey } from './user';

const ONE_HOUR = 1000 * 60 * 60;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: (e) => e instanceof AuthError,
      cacheTime: ONE_HOUR
    },
    mutations: {
      useErrorBoundary: (e) => e instanceof AuthError
    }
  }
});

const companyKey = (cid: string): string => `company-${cid}`;
const companyStructureKey = (cid: string): string => `company-structure-${cid}`;
const favoriteKey = (cid: string): string => `favorite-${cid}`;
const listCompaniesKey = (filter?: Filter, regionId?: string | number): string =>
  `list-companies-${filter || 'unfiltered'}-${regionId}`;
const reportKey = (cid: string, reportType: ReportType): string => `company-report-${cid}-${reportType}`;
const financeKey = (cid: string, holding: boolean, reportType: ReportType, simulate: boolean): string =>
  `company-finance-${cid}-${holding}-${reportType}-${simulate}`;
const personKey = (psid: string): string => `person-${psid}`;
const activitiesKey = (cid: string): string => `activities-${cid}`;

export const useCompanies = (filter?: Filter, region?: number): UseQueryResult<CompanyDbCompany[], Error> => {
  const api = useApi();
  return useQuery(listCompaniesKey(filter, region), () => api.company.list(filter, region));
};

export const useCompany = (cid: string): UseQueryResult<CompanyDbCompany, Error> => {
  const api = useApi();
  return useQuery<CompanyDbCompany, Error>(companyKey(cid), () => api.company.get(cid));
};

export const useRelations = (psid: string): UseQueryResult<PersonalRelation[], Error> => {
  const api = useApi();
  return useQuery<PersonalRelation[], Error>(personKey(psid), () => api.company.getRelations(psid));
};

const invalidateFilters = async (queryClient: QueryClient): Promise<void> => {
  const filters = [undefined, ...Object.values(Filter)];
  const regionIds = [undefined, ...regions.map((region) => region.id)];
  await Promise.all(regionIds.map((r) => filters.map((f) => queryClient.invalidateQueries(listCompaniesKey(f, r)))));
};

interface UpdateFavoritesContext {
  previousLeads: CompanyDbCompany[];
  previousFavorites: CompanyDbCompany[];
}

export const useSetFavorite = (cid: string): UseMutationResult<void, Error, boolean, UpdateFavoritesContext> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, boolean, UpdateFavoritesContext>(
    favoriteKey(cid),
    (isFavorite) => api.company.setFavourite(cid, isFavorite),
    {
      onMutate: async (isFavorite): Promise<UpdateFavoritesContext> => {
        await queryClient.cancelQueries(listCompaniesKey(Filter.Leads));
        await queryClient.cancelQueries(listCompaniesKey(Filter.Favorites));
        const previousLeads = queryClient.getQueryData(listCompaniesKey(Filter.Leads)) as CompanyDbCompany[];
        const previousFavorites = queryClient.getQueryData(listCompaniesKey(Filter.Favorites)) as CompanyDbCompany[];
        const lead = previousLeads?.find((l) => l.cid === cid) as CompanyDbCompany | undefined;
        const favorite = previousFavorites?.find((l) => l.cid === cid) as CompanyDbCompany | undefined;
        queryClient.setQueryData(
          listCompaniesKey(Filter.Leads),
          (leads: CompanyDbCompany[] | undefined): CompanyDbCompany[] => {
            if (favorite) {
              favorite.favorite = false;
            }
            return leads || [];
          }
        );
        queryClient.setQueryData(
          listCompaniesKey(Filter.Favorites),
          (favorites: CompanyDbCompany[] | undefined): CompanyDbCompany[] => {
            const newFavorites = favorites || [];
            if (!isFavorite) {
              return [...newFavorites.filter((x) => x.cid !== cid)];
            }
            if (lead) {
              lead.favorite = true;
              newFavorites.unshift(lead);
            }
            return newFavorites;
          }
        );
        return { previousLeads, previousFavorites };
      },
      onError: (_, _X_, context?: UpdateFavoritesContext): void => {
        if (context) {
          queryClient.setQueryData(listCompaniesKey(Filter.Leads), context.previousLeads);
          queryClient.setQueryData(listCompaniesKey(Filter.Favorites), context.previousFavorites);
        }
      },
      onSettled: async () => await invalidateFilters(queryClient)
    }
  );
};

export const useCompanyStructure = (cid: string): UseQueryResult<CompanyStructure, Error> => {
  const api = useApi();
  return useQuery<CompanyStructure, Error>(companyStructureKey(cid), () => api.company.getCompanyStructure(cid));
};

export const useCompanyReport = (cid: string, reportType: ReportType): UseQueryResult<CompanyReport, Error> => {
  const api = useApi();
  return useQuery<CompanyReport, Error>(reportKey(cid, reportType), () =>
    api.company.getCompanyReport(cid, reportType)
  );
};

export const useFinancials = (
  cid: string,
  holding: boolean,
  reportType: ReportType,
  simulate: boolean
): UseQueryResult<Financials, Error> => {
  const api = useApi();
  return useQuery<Financials, Error>(financeKey(cid, holding, reportType, simulate), () =>
    api.company.getFinancials(cid, holding, reportType, simulate)
  );
};

interface SetInterestingOptions {
  interesting: boolean;
  comment: string;
  interestingOptionIds: string;
}

export const useSetInteresting = (
  cid: string
): UseMutationResult<void, Error, SetInterestingOptions, CompanyDbCompany> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, SetInterestingOptions, CompanyDbCompany>(
    companyKey(cid),
    ({ interesting, comment, interestingOptionIds }) =>
      api.company.setInteresting(cid, interesting, comment, interestingOptionIds),
    {
      onMutate: async ({ interesting, comment, interestingOptionIds }): Promise<CompanyDbCompany> => {
        await queryClient.cancelQueries(companyKey(cid));
        const company = queryClient.getQueryData(companyKey(cid));
        queryClient.setQueryData(companyKey(cid), (c) => ({
          ...(c as CompanyDbCompany),
          interesting: { comment, interesting, interesting_option_ids: interestingOptionIds }
        }));
        return company as CompanyDbCompany;
      },
      onError: (_, __, company?: CompanyDbCompany): void => {
        if (company) {
          queryClient.setQueryData(companyKey(cid), company);
        }
      },
      onSettled: () => queryClient.invalidateQueries(companyKey(cid))
    }
  );
};

export const useUnsetInteresting = (cid: string): UseMutationResult<void, Error, void> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, void>(companyKey(cid), () => api.company.unsetInteresting(cid), {
    onMutate: async (): Promise<CompanyDbCompany> => {
      await queryClient.cancelQueries(companyKey(cid));
      const company = queryClient.getQueryData(companyKey(cid));
      queryClient.setQueryData(companyKey(cid), (c) => ({
        ...(c as CompanyDbCompany),
        interesting: null
      }));
      return company as CompanyDbCompany;
    },
    onSettled: () => queryClient.invalidateQueries(companyKey(cid))
  });
};

export type AddOrUpdateActivityOptions = {
  followUpDate?: string;
  note: string;
} & (
  | {
      activityId: string;
    }
  | {
      type: ActivityType;
    }
);

export const useActivities = (cid: string): UseQueryResult<Activity[], Error> => {
  const api = useApi();
  return useQuery<Activity[], Error>(activitiesKey(cid), () => api.company.getActivities(cid));
};

export const useAddActivity = (cid: string): UseMutationResult<void, Error, AddOrUpdateActivityOptions, Activity[]> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, AddOrUpdateActivityOptions, Activity[]>(
    companyKey(cid),
    async ({ followUpDate, note, ...typeProps }) => {
      if ('activityId' in typeProps) {
        await api.company.updateActivity(cid, typeProps.activityId, note, followUpDate);
      } else {
        await api.company.addActivity(cid, typeProps.type, note, followUpDate);
      }
    },
    {
      onMutate: async ({ followUpDate, note, ...typeProps }): Promise<Activity[]> => {
        await queryClient.cancelQueries(activitiesKey(cid));
        const activities = queryClient.getQueryData(activitiesKey(cid));
        queryClient.setQueryData(activitiesKey(cid), (a) => {
          const cachedActivities = [...(a as Activity[])];
          if ('activityId' in typeProps) {
            const edited = cachedActivities?.find((a) => a.activity_id === typeProps.activityId);
            if (edited) {
              edited.note = note;
              edited.follow_up_date = followUpDate;
            }
          } else {
            cachedActivities.unshift({ follow_up_date: followUpDate, note, type: typeProps.type });
          }
          return cachedActivities;
        });
        return activities as Activity[];
      },
      onError: (_, __, activities?: Activity[]): void => {
        if (activities) {
          queryClient.setQueryData(activitiesKey(cid), activities);
        }
      },
      onSettled: () => queryClient.invalidateQueries(activitiesKey(cid))
    }
  );
};

interface MarkActivityDoneOptions {
  activityId: string;
}

interface MarkActivityDoneCacheHandle {
  activities?: CompanyDbCompany;
  followUps?: FollowUp[];
}

export const useMarkActivityDone = (
  cid: string
): UseMutationResult<void, Error, MarkActivityDoneOptions, MarkActivityDoneCacheHandle> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, MarkActivityDoneOptions, MarkActivityDoneCacheHandle>(
    activitiesKey(cid),
    ({ activityId }) => api.company.markActivityDone(cid, activityId),
    {
      onMutate: async ({ activityId }): Promise<MarkActivityDoneCacheHandle> => {
        const handle: MarkActivityDoneCacheHandle = {};
        await queryClient.cancelQueries(activitiesKey(cid));
        const activities = queryClient.getQueryData(activitiesKey(cid));
        if (activities) {
          queryClient.setQueryData(activitiesKey(cid), (a) => {
            const cachedActivities = [...(a as Activity[])];
            const changed = cachedActivities.find((x) => x.activity_id === activityId);
            if (changed) {
              changed.follow_up_done = true;
            }
            return cachedActivities;
          });
          handle.activities = activities as CompanyDbCompany;
        }
        await queryClient.cancelQueries(followUpsCacheKey);
        const cachedFollowUps = queryClient.getQueryData(followUpsCacheKey);
        if (cachedFollowUps) {
          queryClient.setQueryData(followUpsCacheKey, (f) => {
            return (f as FollowUp[]).filter((entry) => entry.cid !== cid || entry.activity_id !== activityId);
          });
          handle.followUps = cachedFollowUps as FollowUp[];
        }
        return handle;
      },
      onError: (_, __, cacheHandle?: MarkActivityDoneCacheHandle): void => {
        if (cacheHandle?.activities) queryClient.setQueryData(activitiesKey(cid), cacheHandle?.activities);
        if (cacheHandle?.followUps) queryClient.setQueryData(followUpsCacheKey, cacheHandle?.followUps);
      },
      onSettled: (_, __, ___, cacheHandle?: MarkActivityDoneCacheHandle) => {
        if (cacheHandle?.activities) queryClient.invalidateQueries(activitiesKey(cid));
        if (cacheHandle?.followUps) queryClient.invalidateQueries(followUpsCacheKey);
      }
    }
  );
};

interface DeleteActivityOptions {
  activityId: string;
}

export const useDeleteActivity = (cid: string): UseMutationResult<void, Error, DeleteActivityOptions, Activity[]> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, DeleteActivityOptions, Activity[]>(
    companyKey(cid),
    ({ activityId }) => api.company.deleteActivity(cid, activityId),
    {
      onMutate: async ({ activityId }): Promise<Activity[] | undefined> => {
        await queryClient.cancelQueries(activitiesKey(cid));
        const activities = queryClient.getQueryData(activitiesKey(cid));
        if (!activities) return;

        queryClient.setQueryData(activitiesKey(cid), (a) => {
          const cachedActivities = a as Activity[];
          return cachedActivities?.filter((x) => x.activity_id !== activityId);
        });
        return activities as Activity[];
      },
      onError: (_, __, activities?: Activity[]): void => {
        if (activities) queryClient.setQueryData(activitiesKey(cid), activities);
      },
      onSettled: (_, __, ___, activities?: Activity[]) => {
        if (activities) queryClient.invalidateQueries(activitiesKey(cid));
      }
    }
  );
};

export interface SaveFinancialPeriodOptions {
  period: string;
  fields: FieldDataInput[];
}

export const useSaveFinancialPeriod = (
  cid: string
): UseMutationResult<void, Error, SaveFinancialPeriodOptions, void> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, SaveFinancialPeriodOptions, void>(
    'period' + cid,
    ({ period, fields }) => api.company.saveFinancialPeriod(cid, period, fields),
    {
      onSettled: () =>
        Promise.all([
          queryClient.invalidateQueries(reportKey(cid, ReportType.CreditMemo)),
          queryClient.invalidateQueries(reportKey(cid, ReportType.Financials)),
          queryClient.invalidateQueries(reportKey(cid, ReportType.Rating))
        ])
    }
  );
};

export interface AddSimulationOptions {}
export const useAddSimulation = (
  cid: string,
  holding: boolean
): UseMutationResult<void, Error, AddSimulationOptions, void> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, AddSimulationOptions, void>(
    'sim' + cid,
    () => api.company.addSimulation(cid, holding),
    {
      onSettled: () =>
        Promise.all([
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.CreditMemo, true)),
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.Financials, true)),
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.Rating, true))
        ])
    }
  );
};
export interface UpdateSimulationOptions {
  id: string;
  field: string;
  value: number;
  note: string;
}
export const useUpdateSimulation = (
  cid: string,
  holding: boolean
): UseMutationResult<void, Error, UpdateSimulationOptions, void> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, UpdateSimulationOptions, void>(
    'period' + cid,
    ({ id, field, value, note }) => api.company.updateSimulation(cid, holding, id, field, value, note),
    {
      onSettled: () =>
        Promise.all([
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.CreditMemo, true)),
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.Financials, true)),
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.Rating, true))
        ])
    }
  );
};
export interface DeleteSimulationOptions {
  id: string;
}
export const useDeleteSimulation = (
  cid: string,
  holding: boolean
): UseMutationResult<void, Error, DeleteSimulationOptions, void> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, DeleteSimulationOptions, void>(
    'sim' + cid,
    ({ id }) => api.company.deleteSimulation(cid, holding, id),
    {
      onSettled: () =>
        Promise.all([
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.CreditMemo, true)),
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.Financials, true)),
          queryClient.invalidateQueries(financeKey(cid, holding, ReportType.Rating, true))
        ])
    }
  );
};

interface updateOwnershipPercentageOptions {
  owner: string;
  subsidiary: string;
  ownershipPercentage: string;
}

export const useUpdateOwnershipPercentage = (
  cid: string
): UseMutationResult<void, Error, updateOwnershipPercentageOptions, void> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, updateOwnershipPercentageOptions, void>(
    companyStructureKey(cid),
    ({ owner, subsidiary, ownershipPercentage }) =>
      api.company.updateOwnershipPercentage(owner, subsidiary, ownershipPercentage),
    {
      onSettled: () => queryClient.invalidateQueries(companyStructureKey(cid))
    }
  );
};

interface DeleteFinancialPeriodOptions {
  period: string;
}

export const useDeleteFinancialPeriod = (
  cid: string
): UseMutationResult<void, Error, DeleteFinancialPeriodOptions, void> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, DeleteFinancialPeriodOptions, void>(
    'period' + cid,
    ({ period }) => api.company.deleteFinancialPeriod(cid, period),
    {
      onSettled: () =>
        Promise.all([
          queryClient.invalidateQueries(reportKey(cid, ReportType.CreditMemo)),
          queryClient.invalidateQueries(reportKey(cid, ReportType.Financials)),
          queryClient.invalidateQueries(reportKey(cid, ReportType.Rating))
        ])
    }
  );
};

export const useTransferToCrm = (cid: string): UseMutationResult<void, Error, void, CompanyDbCompany> => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<void, Error, void, CompanyDbCompany>(companyKey(cid), () => api.company.transferToCrm(cid), {
    onMutate: async (): Promise<CompanyDbCompany> => {
      await queryClient.cancelQueries(companyKey(cid));
      const company = queryClient.getQueryData(companyKey(cid));
      queryClient.setQueryData(companyKey(cid), (c) => ({
        ...(c as CompanyDbCompany),
        ncino: true
      }));
      return company as CompanyDbCompany;
    },
    onError: (_, __, company?: CompanyDbCompany): void => {
      if (company) {
        queryClient.setQueryData(companyKey(cid), company);
      }
    },
    onSettled: () => queryClient.invalidateQueries(companyKey(cid))
  });
};
