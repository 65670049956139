import { Done } from '@mui/icons-material';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { useMarkActivityDone } from '../api/company';
import { useFollowUp } from '../api/user';
import { useI18n } from '../i18n/i18n';

const MarkDoneButton: React.FC<{ cid: string; activityId?: string }> = ({ cid, activityId }) => {
  const markActivityDone = useMarkActivityDone(cid);
  return (
    <IconButton
      size="small"
      disabled={!activityId}
      onClick={() => markActivityDone.mutateAsync({ activityId: activityId! })}
    >
      <Done />
    </IconButton>
  );
};

export const FollowUp: React.FC<HTMLAttributes<HTMLDivElement>> = ({ ...domAttributes }) => {
  const followUps = useFollowUp();
  const { i18n } = useI18n();
  return (
    <Paper {...domAttributes} style={{ overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 200 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  height: 18,
                  minWidth: 150,
                  fontWeight: 400
                }}
              >
                {i18n('follow_up.company', 'Company')}
              </TableCell>
              <TableCell
                align="left"
                style={{
                  height: 18,
                  minWidth: 100,
                  fontWeight: 400
                }}
              >
                {i18n('followup.note', 'Note')}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  height: 18,
                  maxWidth: 100,
                  fontWeight: 400
                }}
              >
                {i18n('follow_up.follow_up', 'Follow up')}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  height: 18,
                  minWidth: 80,
                  fontWeight: 400
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {followUps.data?.map((followUp, index) => (
              <TableRow key={index}>
                <TableCell
                  align="left"
                  style={{
                    height: 18,
                    minWidth: 150,
                    padding: '8px 16px'
                  }}
                >
                  <Link
                    state={{ cid: followUp?.cid, name: followUp?.company?.name }}
                    to={`/companies/${followUp?.cid}/`}
                  >
                    {followUp.company?.name}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    height: 18,
                    maxWidth: 0,
                    width: '100%',
                    padding: '8px 16px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {followUp.note}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    height: 18,
                    minWidth: 90,
                    padding: '8px 16px'
                  }}
                >
                  {followUp.follow_up_date}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    height: 18,
                    minWidth: 40,
                    padding: '8px 16px'
                  }}
                >
                  <MarkDoneButton cid={followUp.cid!} activityId={followUp.activity_id || undefined} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
