import React, { ChangeEvent, HTMLAttributes, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon, IconButton, Paper, colors } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { NavLink } from 'react-router-dom';
import { RelativeRoute, useConsumeTopbarProperties } from './topbarContext';
import { useSearch } from '../../pages/search';
import { Logout, Settings } from '@mui/icons-material';
import { signOut } from '../../api/auth';
import { PopupMenu } from '../PopupMenu';
import { RouteProps } from '../navigation';
import { topbarBreakWidth } from '../layout';
import { useI18n } from '../../i18n/i18n';

const Content = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0px 8px;
  background: white;
  height: 40px;
`;

const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const CustomControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 8px;

  @media (max-width: ${topbarBreakWidth}px) {
    flex: 1;
  }
`;

const Input = styled.input`
  min-width: 200px;
  padding: 8px;
  border: 1px solid ${blueGrey[100]};
  border-radius: 3px;
  outline: none;
  margin: 0;

  @media (max-width: ${topbarBreakWidth}px) {
    flex: 1;
    min-width: 0;
    max-width: 50%;
  }
`;

interface TopbarProps extends HTMLAttributes<HTMLDivElement> {
  routes: RouteProps[];
}

const OrderedList = styled.ol`
  display: flex;
  list-style-type: none;
  padding: 0;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  font-size: 0.9em;
  color: ${colors.grey[700]};
  margin-bottom: 5px;
  padding: 0 8px;
  border-bottom: 5px solid transparent;
  height: 40px;

  &[class*='active'] {
    border-bottom: 5px solid ${colors.grey[700]};
  }

  @media (max-width: 900px) {
    font-size: 0.7em;
    padding: 0 4px;
  }
`;

const Nav = styled.nav<{ maxWidth?: number }>`
  ${(p) =>
    p.maxWidth &&
    css`
      @media (max-width: ${p.maxWidth}px) {
        display: none;
      }
    `}
`;

const Menu: React.FC<{ routes: RouteProps[]; relativeRoutes?: RelativeRoute[]; maxWidth?: number }> = ({
  routes,
  relativeRoutes,
  maxWidth
}) => (
  <Nav maxWidth={maxWidth}>
    <OrderedList>
      {routes
        .filter((r) => r.menuEntry === 'always')
        .map((r) => (
          <li key={r.path}>
            <StyledNavLink to={r.path}>
              <Icon>{r.icon}</Icon>
              {r.header}
            </StyledNavLink>
          </li>
        ))}
      {relativeRoutes?.map((r) => (
        <li key={r.path}>
          <StyledNavLink to={r.path}>
            <Icon>{r.icon}</Icon>
            {r.header}
          </StyledNavLink>
        </li>
      ))}
    </OrderedList>
  </Nav>
);

const BottomBar = styled.div<{ minWidth: number }>`
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (max-width: ${(p) => p.minWidth}px) {
    display: block;
    z-index: 100;
  }
`;

export const Topbar: React.FC<TopbarProps> = ({ routes, ...domAttributes }) => {
  const { i18n } = useI18n();
  const { actions, relativeRoutes } = useConsumeTopbarProperties();
  const navigate = useNavigate();
  const { search, clearSearch } = useSearch();

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { value } = e.target;
      if (value) {
        search(value);
      } else {
        clearSearch();
      }
    },
    [search, clearSearch]
  );

  return (
    <>
      <Container {...domAttributes}>
        <Content elevation={6} style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          <div>
            <Menu routes={routes} relativeRoutes={relativeRoutes} maxWidth={topbarBreakWidth} />
          </div>
          <CustomControlsContainer>
            {actions?.map(({ key, icon, action }) => (
              <React.Fragment key={key}>
                {action ? (
                  <IconButton
                    key={key}
                    onClick={(e) => {
                      e.bubbles = false;
                      e.stopPropagation();
                      e.preventDefault();
                      action();
                    }}
                  >
                    {icon}
                  </IconButton>
                ) : (
                  icon
                )}
              </React.Fragment>
            ))}
            <Input
              onChange={onSearch}
              name="searchbar"
              type="search"
              placeholder={i18n('search.placeholder', 'search')}
            />
            <PopupMenu
              style={{ marginLeft: 4 }}
              items={[
                {
                  key: 'profile',
                  header: i18n('menu.profile', 'Profile'),
                  Icon: Settings,
                  action: () => {
                    navigate('/profile');
                  }
                },
                { key: 'signout', header: i18n('menu.signout', 'Sign out'), Icon: Logout, action: signOut }
              ]}
            />
          </CustomControlsContainer>
        </Content>
      </Container>
      <BottomBar minWidth={topbarBreakWidth}>
        <Content elevation={6} style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <Menu routes={routes} relativeRoutes={relativeRoutes} />{' '}
        </Content>
      </BottomBar>
    </>
  );
};
