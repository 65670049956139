import { colors, Paper, Typography, useMediaQuery } from '@mui/material';
import React, { CSSProperties, HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CompanyDbCompany } from '../generated/graphql';
import { useI18n } from '../i18n/i18n';
import { LeadGauge } from './LeadGauge';
import { SetFavoriteButton } from './setFavoriteButton';
import { SkeletonElement } from './skeletonElement';

export interface CompanyListProps extends React.HTMLAttributes<HTMLDivElement> {
  companies: CompanyDbCompany[];
  loadingCompanies?: { key: string | number; favorite?: boolean }[];
}

const List = styled(Paper)`
  > * + * {
    border-top: 1px solid ${colors.grey[300]};
  }
`;

interface CompanyListItemProps {
  company?: CompanyDbCompany;
  loadingAsFavorite?: boolean;
  isNarrow?: boolean;
}

interface CreditInfoProps extends HTMLAttributes<HTMLDivElement> {
  header: string;
  value: string;
}

const CreditInfo: React.FC<CreditInfoProps> = ({ header, value, ...domAttributes }) => (
  <div {...domAttributes}>
    <Typography variant="body2">
      {header}: <strong>{value}</strong>
    </Typography>
  </div>
);

const ItemHeader = styled(Typography)`
  font-size: 0.8em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
  cursor: pointer;
`;

const MainItemContent = styled.div`
  flex: 1;
  padding: 0 8px;
  @media (max-width: 500px) {
    > * + * {
      margin-top: 8px;
    }
  }
`;

const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  > * + * {
    margin-left: 8px;
  }
`;

const CompanyKeyValues: React.FC<{ company?: CompanyDbCompany; itemStyling?: CSSProperties }> = ({
  company,
  itemStyling
}) => {
  const { i18n } = useI18n();
  return (
    <>
      <CreditInfo
        style={itemStyling}
        header={i18n('caption.rating', 'Rating')}
        value={company?.rating ? (company?.rating).toFixed(0) : '-'}
      />
      <CreditInfo
        style={itemStyling}
        header={i18n('caption.exceptions', 'Exceptions')}
        value={`${company?.credit_flags_sum || 0}/13`}
      />
      <CreditInfo
        style={itemStyling}
        header={i18n('caption.max', 'Max')}
        value={(company?.max_loan_amount || 0).toLocaleString()}
      />
    </>
  );
};

const CompanyListItem: React.FC<CompanyListItemProps> = ({ company, loadingAsFavorite }) => {
  const navigate = useNavigate();
  const isNarrow = useMediaQuery('(max-width: 600px)');

  return (
    <ItemContainer
      onClick={
        company
          ? () => navigate(`/companies/${company.cid}/`, { state: { cid: company.cid, name: company.name } })
          : undefined
      }
    >
      {!isNarrow && (
        <LeadGauge style={{ marginRight: 8 }} height={30} width={30} probability={company?.probability || 0} />
      )}
      <MainItemContent>
        <ItemContent>
          {company ? (
            <ItemHeader variant="subtitle1">{company.name}</ItemHeader>
          ) : (
            <SkeletonElement style={{ marginTop: 4 }} width="30%" />
          )}
          {company ? (
            <Typography style={{ marginLeft: 8 }} variant="subtitle2" textAlign="right">
              {company.address_region}
            </Typography>
          ) : (
            <SkeletonElement style={{ marginTop: 4 }} width="20%" />
          )}
        </ItemContent>
        <ItemContent>
          {!isNarrow && (
            <ItemContent>
              <CompanyKeyValues company={company} />
            </ItemContent>
          )}
          {company ? (
            <Typography style={{ flex: 2 }} textAlign={isNarrow ? 'left' : 'right'} variant="subtitle2">
              {company.industry_domestic_name}
            </Typography>
          ) : (
            <SkeletonElement style={{ marginTop: 8 }} width="30%" />
          )}
        </ItemContent>
        {isNarrow && (
          <ItemContent style={{ alignItems: 'center' }}>
            <LeadGauge style={{ marginRight: 8 }} height={30} width={30} probability={company?.probability || 0} />
            <CompanyKeyValues itemStyling={{ textAlign: 'center' }} />
          </ItemContent>
        )}
      </MainItemContent>
      <SetFavoriteButton cid={company?.cid} favorite={company?.favorite || loadingAsFavorite} />
    </ItemContainer>
  );
};

export const CompanyList: React.FC<CompanyListProps> = ({ companies, loadingCompanies, ...domAttributes }) => {
  return (
    <List {...domAttributes} sx={{ borderRadius: 1 }}>
      {companies.map((company) => (
        <CompanyListItem key={company.cid} company={company} />
      ))}
      {loadingCompanies?.map((company) => (
        <CompanyListItem key={company.key} loadingAsFavorite={company.favorite} />
      ))}
    </List>
  );
};
