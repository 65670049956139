import { Component } from 'react';
import { AuthError, getAuthToken, redirectToCognitoLogin } from '../api/auth';
import { apiKey } from '../environment';
import { useI18n } from '../i18n/i18n';

const RedirectMessage: React.FC = () => {
  const { i18n } = useI18n();
  return <p style={{ color: '#fff' }}>{i18n('authpage.redirect_message', 'Redirect to sign in')}</p>;
};

export class AuthBoundary extends Component<{ children: JSX.Element }, { isAuthed: boolean }> {
  public constructor(props: { children: JSX.Element }) {
    super(props);
    this.state = { isAuthed: false };
  }

  public componentDidCatch(error: Error): void {
    if (error instanceof AuthError) {
      this.setState(() => ({ isAuthed: false }));
      redirectToCognitoLogin();
      return;
    }
    throw error;
  }

  public componentDidMount(): void {
    if (getAuthToken() || apiKey) {
      this.setState(() => ({ isAuthed: true }));
    } else {
      redirectToCognitoLogin();
    }
  }

  public render(): JSX.Element {
    return this.state.isAuthed ? (
      this.props.children
    ) : (
      <div>
        <RedirectMessage />
      </div>
    );
  }
}
